import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import classNames from 'classnames';
import { endOfWeek, format, parse, set } from 'date-fns';
import { useMemo, useState } from 'react';
import {
  BiCalendar,
  BiCog,
  BiDownload,
  BiRefresh,
  BiTable,
} from 'react-icons/bi';
import { useMediaQuery } from 'react-responsive';
import useSWR from 'swr';
import { default as useSWRInfinite } from 'swr/infinite';
import uniqolor from 'uniqolor';
import { Button, Modal, Spinner } from '../../lib';
import apiServices from '../../services/apiServices';
import jobServices from '../../services/jobServices';
import memberInfoServices from '../../services/memberInfoSvcs';

const BASE_URL = apiServices.apiUrl();
const jobService = new jobServices();
const membersService = new memberInfoServices();

type View = 'calendar' | 'list' | 'board';
const getKey = (pageIndex: number, previousPageData: any[]) => {
  if (previousPageData && !previousPageData.length) return null;
  return `jobs-list/${pageIndex}`;
};
function NewJobHistory() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [view, setView] = useState<View>(() => {
    const storedView = localStorage.getItem('VIEW') as View;
    return storedView ?? 'calendar';
  });
  const [range, setRange] = useState<{
    start: string;
    end: string;
    current: string;
  }>();
  const [selectedJob, setSelectedJob] = useState<string>();

  const swrOptions = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  };
  const memberQuery = useSWR(
    'members',
    async () => {
      const { local_feature_toggles } = await membersService.getMemberInfo();
      return local_feature_toggles?.dont_display_show != 'Y';
    },
    swrOptions
  );

  const jobsQuery = useSWRInfinite(
    view === 'list' ? getKey : null,
    (key) => {
      const page = +key.split('/').pop();
      if (!page) return jobService.getJobHistory().then((d) => d.job_history);
      return jobService.getJobHistory_older().then((d) => d.job_history);
    },
    swrOptions
  );

  const calendarJobsQuery = useSWR(
    view === 'calendar' && range ? `jobs/${range.start}/${range.end}` : null,
    async () => {
      const params = {
        startDate: format(range.start, 'yyyy-MM-dd'),
        endDate: format(range.end, 'yyyy-MM-dd'),
        skip: 0,
        take: 300,
      };
      const data = await jobService.getJobHistory(params);
      return data.job_history;
    },
    swrOptions
  );

  const jobDetails = [
    ...(calendarJobsQuery.data ?? []),
    ...(jobsQuery.data ?? []),
  ]
    .flat()
    .find((j) => j.dispatch_Ident.toString() === selectedJob);

  const events: React.ComponentProps<typeof FullCalendar>['events'] = useMemo(
    () =>
      calendarJobsQuery.data?.map((job) => ({
        start: combineDateAndTime(job.starting_Date, job.starting_time),
        end: combineDateAndTimeEnd(job.ending_Date),
        color: uniqolor(job.id.toString()).color,
        title: `${job.job_ID} ${memberQuery.data ? job.show : ''}`,
        interactive: true,
        id: job.dispatch_Ident.toString(),
      })),
    [calendarJobsQuery.data]
  );

  return (
    <div className="mx-auto w-full pb-12 flex h-full max-w-7xl flex-col gap-6  dark:text-gray-400">
      <div className="flex gap-2 items-center justify-between">
        <h1 className="text-2xl font-bold">Job History</h1>
        <div className="inline-flex rounded-md shadow-sm" role="group">
          {[
            {
              key: 'calendar' as const,
              icon: <BiCalendar className="w-5 h-5" />,
              label: 'Calendar View',
            },
            {
              key: 'list' as const,
              icon: <BiTable className="w-5 h-5" />,
              label: 'List View',
            },
          ].map(({ label, icon, key }) => {
            return (
              <button
                key={key}
                type="button"
                onClick={() => {
                  setView(key);
                  localStorage.setItem('VIEW', key);
                }}
                className={classNames(
                  'inline-flex gap-2 items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded hover:bg-gray-100 hover:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 ',

                  view === key &&
                    ' z-10 dark:ring-blue-500 dark:text-white ring-2 ring-blue-700 text-blue-700 '
                )}
              >
                {icon}
                <span className="hidden md:block">{label}</span>
              </button>
            );
          })}
        </div>
      </div>

      {memberQuery.isLoading && (
        <div className="flex justify-center">
          <Spinner color="info" size={'xl'} />
        </div>
      )}

      {memberQuery.data && (
        <div
          className={classNames(
            'relative flex-1 transition-all z-10',
            calendarJobsQuery.isLoading && 'opacity-70'
          )}
        >
          {(calendarJobsQuery.isLoading || jobsQuery.isLoading) && (
            <div className="absolute inset-0 lg:translate-y-0 -translate-y-20 flex justify-center items-center">
              <Spinner color="success" size={'xl'} />
            </div>
          )}

          {view === 'calendar' && (
            <FullCalendar
              initialDate={range?.current}
              plugins={[interactionPlugin, dayGridPlugin]}
              datesSet={(info) => {
                setRange({
                  start: info.startStr,
                  end: info.endStr,
                  current: endOfWeek(info.startStr).toISOString(),
                });
              }}
              initialView="dayGridMonth"
              events={events}
              headerToolbar={{
                left: 'title',
                center: '',
                right: 'today prev,next',
              }}
              displayEventTime={false}
              eventClick={(info) => setSelectedJob(info.event.id)}
              buttonText={{
                today: 'Today',
              }}
              height={isMobile ? 'auto' : '95%'}
              fixedWeekCount={false}
              viewClassNames="dark:bg-gray-900"
            />
          )}
          {view === 'list' && (
            <div className="grid gap-2 pb-12">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="py-3 px-3 md:px-6">
                      Job #
                    </th>

                    <th scope="col" className="py-3 px-3 md:px-6">
                      Starts
                    </th>

                    <th
                      scope="col"
                      className="py-3 px-3 md:px-6 hidden md:table-cell"
                    >
                      Employer
                    </th>

                    {memberQuery.data && (
                      <th
                        scope="col"
                        className="py-3 px-3 md:px-6 hidden md:table-cell"
                      >
                        Show
                      </th>
                    )}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {jobsQuery.data?.flat().map((job) => {
                    return (
                      <tr
                        key={job.id}
                        className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                      >
                        <td className="py-4 px-3 md:px-6">
                          <button
                            onClick={() =>
                              setSelectedJob(job.dispatch_Ident.toString())
                            }
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                          >
                            <span>{job.job_ID}</span>
                          </button>
                        </td>

                        <td className="py-4 px-3 md:px-6">
                          {job.starting_date_display} {job.starting_time}
                        </td>

                        <td className="py-4 px-3 md:px-6 hidden md:table-cell">
                          {job.employer}
                        </td>

                        {memberQuery.data && (
                          <td className="py-4 px-3 md:px-6 hidden md:table-cell">
                            {job.show}
                          </td>
                        )}
                        <td>
                          <div className="flex gap-2">
                            <a
                              href={`${BASE_URL}/member/calenderdownload/${job.dispatch_Ident}/${job.line_Number}/${job.unique_member_id}`}
                              download
                            >
                              <Button color="gray" size="sm">
                                <BiDownload className="w-5 h-5" />

                                <span className="hidden md:inline-block ml-2">
                                  Calendar
                                </span>
                              </Button>
                            </a>
                            <Button
                              onClick={() =>
                                setSelectedJob(job.dispatch_Ident.toString())
                              }
                              color="info"
                              size="sm"
                            >
                              <BiCog className="w-5 h-5" />
                              <span className="hidden md:inline-block ml-2">
                                Details
                              </span>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {jobsQuery.size === 2 && jobsQuery.isValidating && (
                <div className="flex justify-center mt-4">
                  <Spinner color="info" size={'lg'} />
                </div>
              )}

              {jobsQuery.size === 1 && !jobsQuery.isLoading && (
                <div className="flex justify-center py-6">
                  <Button color="gray" onClick={() => jobsQuery.setSize(2)}>
                    <BiRefresh className="w-5 h-5 mr-2" />
                    Load more
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {selectedJob && jobDetails && (
        <Modal show={!!selectedJob} onClose={() => setSelectedJob(null)}>
          <Modal.Header>Job Details</Modal.Header>
          <Modal.Body>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Starting Date
                  </th>
                  <td scope="col" className="px-6 py-3">
                    {jobDetails.starting_date_display}{' '}
                    {jobDetails.starting_time}
                  </td>
                </tr>
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Ending Date
                  </th>
                  <td scope="col" className="px-6 py-3">
                    {jobDetails.ending_date_display} {jobDetails.finish_Time}
                  </td>
                </tr>
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Employer
                  </th>
                  <td scope="col" className="px-6 py-3">
                    {jobDetails.employer}
                  </td>
                </tr>
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Job Site
                  </th>
                  <td scope="col" className="px-6 py-3">
                    {jobDetails.site}
                  </td>
                </tr>

                <tr>
                  <th scope="col" className="px-6 py-3">
                    Report To
                  </th>
                  <td scope="col" className="px-6 py-3">
                    {jobDetails.report_To_Location}
                  </td>
                </tr>
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Skill
                  </th>
                  <td scope="col" className="px-6 py-3">
                    {jobDetails.skill}
                  </td>
                </tr>

                <tr>
                  <th scope="col" className="px-6 py-3">
                    Load Out
                  </th>
                  <td scope="col" className="px-6 py-3">
                    {jobDetails.load_out_display}
                  </td>
                </tr>
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Notes
                  </th>
                  <td scope="col" className="px-6 py-3">
                    {jobDetails.lineNotes}
                  </td>
                </tr>

                <tr>
                  <th scope="col" className="px-6 py-3">
                    On Site Parking?
                  </th>
                  <td scope="col" className="px-6 py-3">
                    {jobDetails.onSiteParking == null
                      ? ''
                      : jobDetails.onSiteParking
                      ? 'Yes'
                      : 'No'}
                  </td>
                </tr>

                {memberQuery.data && (
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Show
                    </th>
                    <td scope="col" className="px-6 py-3">
                      {jobDetails.show}
                    </td>
                  </tr>
                )}

                <tr>
                  <th scope="col" className="px-6 py-3">
                    Steward Info
                  </th>
                  <td scope="col" className="px-6 py-3">
                    {jobDetails.steward_Fname} {jobDetails.steward_Lname}{' '}
                    {jobDetails.steward_Phone}
                  </td>
                </tr>
              </thead>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <a
              href={`${BASE_URL}/member/calenderdownload/${jobDetails.dispatch_Ident}/${jobDetails.line_Number}/${jobDetails.unique_member_id}`}
              download
            >
              <Button color="info">
                <BiDownload className="w-5 h-5 mr-2" />
                Add to calendar
              </Button>
            </a>

            <div className="flex-1 flex justify-end">
              <Button color="gray" onClick={() => setSelectedJob(undefined)}>
                Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

function combineDateAndTime(date: string, time = '12:00 AM') {
  // Parse the date string
  const parsedDate = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());

  // Parse the time string
  const parsedTime = parse(time, 'hh:mm a', parsedDate);

  // Combine date and time
  const datetime = set(parsedDate, {
    hours: parsedTime.getHours(),
    minutes: parsedTime.getMinutes(),
    seconds: 0,
  });

  return datetime;
}

function combineDateAndTimeEnd(date: string) {
  // Parse the date string
  const parsedDate = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());

  // Parse the time string
  const parsedTime = parse("23:59", 'HH:mm', parsedDate);

  // Combine date and time
  const datetime = set(parsedDate, {
    hours: parsedTime.getHours(),
    minutes: parsedTime.getMinutes(),
    seconds: 0,
  });

  return datetime;
}



export default NewJobHistory;
