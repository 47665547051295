import apiServices from './apiServices';
import { GetJobOfferFromShortCode,JobOfferState, MultiJobOfferResponse}  from './dtos';



class jobOfferServices  {
    

     baseSvc = apiServices;
     client = this.baseSvc.client;

   
    constructor() {
     
      }

    
    getJobOffer(shortCode:string)
    {
      const request = new GetJobOfferFromShortCode();
      request.shortCode =shortCode;
        
      return this.client.get(request)

    }


    respondToJobOffer(shortCode: string, accepted:boolean)
    {

        if(shortCode.substring(0,1)=="*")
        {

          let req = new MultiJobOfferResponse();
          
          req.accepted = accepted;
          req.shortCode = shortCode;
          

          return this.client.put(req);

        }

        else
        {
  
          let req = new JobOfferState();
          
          req.accepted = accepted;
          req.shortCode = shortCode;
          

          return this.client.put(req);
        }


    }
      
    
}




export default jobOfferServices;
