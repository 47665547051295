import { useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { FiSend } from 'react-icons/fi';
import {
  HiInformationCircle,
  HiOutlineExclamationCircle,
} from 'react-icons/hi';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import {
  Alert,
  Button,
  Checkbox,
  Label,
  Modal,
  Select,
  Spinner,
  Textarea,
} from '../../lib';
import jobServices from '../../services/jobServices';
import useAsync from '../hooks/useAsync';

const service = new jobServices();
function TextMembersOnJob() {
  const [jobID, setJobID] = useState('');
  const [selectedMembersIDs, setSelectedMembersIds] = useState<string[]>([]);
  const [message, setMessage] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const jobsQuery = useAsync(
    async () => {
      return service.getJobsForSteward();
    },
    true,
    []
  );
  const membersQuery = useAsync(
    async (job: string) => {
      const data = await service.getMembersForJob(job);
      setSelectedMembersIds(data.map((m) => m.unique_member_id));
      return data;
    },
    false,
    [jobID]
  );
  const sendMessageMutation = useAsync(
    async () => {
      await service.sendJobMembersSMS({
        job_id: jobID,
        sms_message: message.trim(),
        unique_member_ids: selectedMembersIDs,
      });
    },
    false,
    []
  );

  const reset = () => {
    setMessage('');
    setIsAlertOpen(false);
    sendMessageMutation.reset();
  };

  return (
    <div className="mx-auto w-full flex max-w-4xl flex-col gap-8 dark:text-white">
      <h2 className="text-2xl font-bold">Text Members On Job</h2>

      {jobsQuery.isLoading && (
        <div className="flex justify-center">
          <Spinner color="info" size={'xl'} />
        </div>
      )}

      {jobsQuery.isSuccess && (
        <div className="grid gap-8">
          <div className="max-w-md">
            <Select
              id="job"
              value={jobID}
              onChange={(e) => {
                const newJob = e.target.value;
                setJobID(newJob);
                membersQuery.execute(newJob);
              }}
              placeholder="Select a job"
            >
              <option value="" disabled selected>
                Select a job please
              </option>

              {jobsQuery.value.map((job) => {
                return (
                  <option className="p-4" value={job.job_ID} key={job.job_ID}>
                    {job.job_ID} {job.show}
                  </option>
                );
              })}
            </Select>
          </div>

          {membersQuery.isLoading && (
            <div className="flex justify-center">
              <Spinner color="info" size={'xl'} />
            </div>
          )}

          {membersQuery.isSuccess && (
            <>
              {membersQuery.value.length ? (
                <>
                  <div>
                    <div className="flex gap-2 mb-4 items-center text-base font-semibold leading-6 text-gray-900 dark:text-white">
                      Members ( {selectedMembersIDs.length} selected )
                    </div>
                    <div className="relative overflow-x-auto">
                      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-600 dark:text-gray-400">
                          <tr>
                            <th scope="col" className="px-2 md:px-4 py-3">
                              <label className="flex items-center gap-4">
                                <Checkbox
                                  id="accept"
                                  defaultChecked
                                  onChange={(e) => {
                                    if (!e.target.checked) {
                                      setSelectedMembersIds([]);
                                    } else {
                                      setSelectedMembersIds(
                                        membersQuery.value.map(
                                          (m) => m.unique_member_id
                                        )
                                      );
                                    }
                                  }}
                                  checked={
                                    membersQuery.value.length ===
                                    selectedMembersIDs.length
                                  }
                                />
                                Name
                              </label>
                            </th>
                            <th scope="col" className="px-2 md:px-6 py-3">
                              Phone Number
                            </th>
                            <th
                              scope="col"
                              className="hidden md:table-cell px-3 md:px-6 py-2"
                            >
                              Start / End
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {membersQuery.value.map((member) => {
                            return (
                              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th
                                  scope="row"
                                  className="px-2 md:px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                >
                                  <label className="flex items-center gap-4 cursor-pointer">
                                    <Checkbox
                                      id={`member_${member.unique_member_id}`}
                                      checked={selectedMembersIDs.includes(
                                        member.unique_member_id
                                      )}
                                      onChange={(e) => {
                                        if (!e.target.checked) {
                                          setSelectedMembersIds(
                                            selectedMembersIDs.filter(
                                              (id) =>
                                                id !== member.unique_member_id
                                            )
                                          );
                                        } else {
                                          setSelectedMembersIds([
                                            ...selectedMembersIDs,
                                            member.unique_member_id,
                                          ]);
                                        }
                                      }}
                                    />
                                    {member.name}
                                  </label>
                                </th>
                                <td className="px-2 md:px-6 py-4">
                                  {member.phone_number}
                                </td>
                                <td className="hidden md:table-cell px-6 py-4">
                                  <div className="gap-2 flex items-center text-gray-600 dark:text-gray-400 text-sm   ">
                                    {member.starting_date_display}
                                    <BsArrowRight />
                                    {member.ending_date_display}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!selectedMembersIDs.length ? (
                    <Alert color="failure" icon={HiInformationCircle}>
                      No members selected. Please choose at least one member to
                      send a text message.
                    </Alert>
                  ) : (
                    <div className="grid gap-4">
                      <div>
                        <div className="mb-2">
                          <Label htmlFor="message">Message</Label>
                        </div>
                        <Textarea
                          id="message"
                          rows={6}
                          disabled={!selectedMembersIDs.length}
                          readOnly={!selectedMembersIDs.length}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          color={
                            message.trim().length < 15 ? 'failure' : 'success'
                          }
                          helperText={
                            message.trim().length < 15
                              ? 'The text message must be at least 15 characters long.'
                              : undefined
                          }
                        />
                      </div>
                      <Button
                        onClick={() => setIsAlertOpen(true)}
                        disabled={
                          !selectedMembersIDs.length ||
                          message.trim().length < 15
                        }
                        color="success"
                      >
                        <FiSend className="w-5 h-5 mr-3 inline-block" />
                        Send to {selectedMembersIDs.length} members
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <div className="text-center border p-8 border-dashed rounded-lg">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-200"
                  >
                    <path
                      d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                      strokeWidth={2}
                      vectorEffect="non-scaling-stroke"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <h3 className="mt-2 text-xl font-semibold text-gray-900 dark:text-white">
                    No members
                  </h3>
                  <p className="mt-2 max-w-sm mx-auto text-sm text-gray-500 dark:text-gray-300">
                    We couldn't find any members for this job. Please select a
                    job that has members who are still active (End date in
                    future).
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      )}

      <Modal
        show={isAlertOpen}
        size="xl"
        onClose={() => {
          setIsAlertOpen(false);
          if (sendMessageMutation.isSuccess) reset();
        }}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="flex flex-col text-center items-center justify-center">
            {!sendMessageMutation.isSuccess && (
              <>
                <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                  You are about to send the following message to{' '}
                  {selectedMembersIDs.length} members, are you sure?{' '}
                </h3>
                <pre className="w-full dark:bg-gray-600 dark:border-none dark:text-white text-left whitespace-pre-wrap border p-4 text-sm bg-gray-50 text-gray-700 rounded-lg mb-10 ">
                  {message.trim()}
                </pre>
                <div className="flex justify-center gap-4">
                  <Button
                    color="success"
                    onClick={() => {
                      sendMessageMutation.execute();
                    }}
                    disabled={sendMessageMutation.isLoading}
                  >
                    {sendMessageMutation.isLoading && (
                      <div className="mr-2">
                        <Spinner color="success" size={'sm'} />
                      </div>
                    )}
                    Yes, I'm sure
                  </Button>
                  <Button color="info" onClick={() => setIsAlertOpen(false)}>
                    No, cancel
                  </Button>
                </div>
              </>
            )}
            {sendMessageMutation.isSuccess && (
              <>
                <IoIosCheckmarkCircle className="mx-auto mb-4 h-14 w-14 text-green-400 dark:text-green-200" />
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                  Your message has been sent to {selectedMembersIDs.length}{' '}
                  members.
                </h3>
                <Button color="success" onClick={reset}>
                  Close
                </Button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default TextMembersOnJob;
