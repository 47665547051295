import { ChangeEvent, useEffect, useState } from 'react';
import { HiCheck, HiInformationCircle } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  FileInput,
  Label,
  Spinner,
  TextInput,
  Toast,
} from '../../lib';
import apiServices from '../../services/apiServices';
import { local_member_ui } from '../../services/dtos';
import memberInfoServices from '../../services/memberInfoSvcs';

const MemberInfoPage = () => {
  const [member, setMember] = useState<local_member_ui>(new local_member_ui());
  const [emailvalid, setemailvalid] = useState<boolean>(true);
  const [updateFailed, setupdateFailed] = useState<boolean>(false);
  const [newImageUrl, setnewImageUrl] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [phoneValid, setphoneValid] = useState<boolean>(true);
  const [addressValid, setaddressValid] = useState<boolean>(true);
  const [dirty, setdirty] = useState<boolean>(false);
  const [updateOk, setupdateOK] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false);
  const [loadingImage, setloadingImage] = useState<boolean>(false);

  const [isInAdminRole, setIsInAdminRole] = useState<boolean>(false);

  //const context = createContext({navBorOpen:navbarCollapse});

  useEffect(() => {
    let svc = apiServices;

    setIsInAdminRole(svc.hasHole('Admin'));
  });

  //const [toastMessage, setToastMessage] = useState<boolean>(false);

  let navigate = useNavigate();

  setupdateFailed;

  function formatPhoneNumber(phoneNumberString: string): string {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      setphoneValid(true);
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    setphoneValid(false);

    return phoneNumberString;
  }

  const [file, setFile] = useState<File | null>(null);

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files) setloadingImage(true);

    setFile(files[0]);

    var formData = new FormData();
    formData.append('description', files[0].name);
    formData.append('type', 'file');
    formData.append('image', files[0]);

    let svc = apiServices;

    let url = svc.apiUrl() + '/api/UploadMemberImage';

    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: formData,
    }).then((r) => {
      e.target.value = null;
      setloadingImage(false);

      if (r.status == 200 || r.status == 0) {
        r.json().then((o) => {
          setnewImageUrl(o.url);
          member.portal_profile_url_approved = '';
          member.portal_profile_url_pending = o.url;
          setMember(member);
          console.log(member);
          console.log(newImageUrl);

          // e.currentTarget.files = null;

          //this.props.onChange(o.data.link);
        });
      } else {
        alert('Error uploading Image');
      }
    });
  };

  const updateMember = (e: any) => {
    setdirty(true);

    let setVal = e.target.value;
    let setName = e.target.id;

    if (setName.endsWith('phone')) {
      setVal = formatPhoneNumber(setVal);
    }

    setMember((prevState) => ({
      ...prevState,
      [setName]: setVal,
    }));

    if (setName.endsWith('email')) {
      checkEmailValid(setVal);
    }
  };

  function checkAddressValid(): boolean {
    let isFilled =
      member.address_line_1 != null &&
      member.address_city != null &&
      member.address_state != null &&
      member.address_postal_code != null;
    if (!isFilled) {
      setaddressValid(false);
      return false;
    }

    let isValid =
      member.address_line_1.length >= 3 &&
      member.address_city.length >= 2 &&
      member.address_state.length >= 2 &&
      member.address_postal_code.length >= 5;

    setaddressValid(isValid);
    return isValid;
  }

  function checkEmailValid(email: string): void {
    email = email.trim();

    let regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    let found = regexp.test(email);
    if (found) {
      setemailvalid(true);
    } else {
      setemailvalid(false);
    }
  }

  const handleUpdate = () => {
    if (!checkAddressValid()) {
      return;
    }
    setloading(true);

    let svc = new memberInfoServices();
    svc
      .updateMemberInfo(member)
      .then(() => {
        setdirty(false);
        setloading(false);
        setupdateFailed(false);
        setupdateOK(true);
      })
      .catch((e: any) => {
        console.log(e);
        setloading(false);
        setErrorMessage('Error updating your information');
        setupdateFailed(true);
      });
  };

  if (!member.first_name && !loading && !updateFailed) {
    setloading(true);

    let svc = new memberInfoServices();
    svc
      .getMemberInfo()
      .then((data) => {
        // useEffect(() => {
        setMember(data);
        setloading(false);

        // });
      })
      .catch((e: any) => {
        setloading(false);
        console.log(e);
        if (
          e.type == 'RefreshTokenException' ||
          e.responseStatus.errorCode == '403' ||
          e.responseStatus.errorCode == '401'
        ) {
          navigate('/login');
        } else {
          setErrorMessage('Error retrieving your information');
          setupdateFailed(true);
        }
      });
  }

  return (
    <div className="mx-auto w-full flex max-w-4xl flex-col gap-8 dark:text-white">
      <span className="text-2xl font-bold">Member Information</span>

      {member.first_name && (
        <form className="flex flex-col gap-4">
          <div>
            <div className="mb-2 block">
              <Label htmlFor="contact_email" value="Your contact email" />
            </div>
            <TextInput
              id="contact_email"
              type="email"
              placeholder="user@domain.com"
              required
              value={member.contact_email}
              onChange={updateMember}
            />

            {member.contact_email && !emailvalid && (
              <Alert color="failure" icon={HiInformationCircle}>
                Please enter a valid email address
              </Alert>
            )}
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="address_line_1" value="Street Address" />
            </div>
            <TextInput
              id="address_line_1"
              type="text"
              required
              value={member.address_line_1}
              onChange={updateMember}
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="address_line_2" value="Street Address Line 2" />
            </div>
            <TextInput
              id="address_line_2"
              type="text"
              value={member.address_line_2}
              onChange={updateMember}
            />
          </div>
          {!addressValid && (
            <Alert color="failure" icon={HiInformationCircle}>
              Please enter a complete address
            </Alert>
          )}

          <div className="flex justify-between">
            <Label htmlFor="address_city" value="City" />
            <Label htmlFor="address_state" value="State" />
            <Label htmlFor="address_postal_code" value="Zip" />
          </div>

          <div className="flex justify-between">
            <TextInput
              id="address_city"
              type="text"
              required
              value={member.address_city}
              onChange={updateMember}
            />

            <TextInput
              id="address_state"
              type="text"
              required
              value={member.address_state}
              onChange={updateMember}
            />

            <TextInput
              id="address_postal_code"
              type="text"
              required
              value={member.address_postal_code}
              onChange={updateMember}
            />
          </div>

          <div className="flex justify-between">
            <Label htmlFor="mobilePhone" value="Mobile Phone" />
            <Label htmlFor="homePhone" value="Home Phone" />
          </div>

          <div className="flex justify-between" id="select">
            <TextInput
              id="cell_phone"
              width="3/5"
              type="text"
              required
              value={member.cell_phone}
              onChange={updateMember}
            />

            <TextInput
              id="home_phone"
              width="3/5"
              type="text"
              required
              value={member.home_phone}
              onChange={updateMember}
            />
          </div>

          {isInAdminRole ||
            (true && (
              <div id="fileUpload">
                {loadingImage && <Spinner color="success" />}

                {!loadingImage && (
                  <div>
                    <div className="mb-2 block">
                      <Label
                        htmlFor="filechoose"
                        value="Upload a profile picture"
                      />
                    </div>
                    <FileInput
                      onChange={onFileChange}
                      id="filechoose"
                      helperText=""
                    />

                    {(newImageUrl ||
                      member.portal_profile_url_pending ||
                      member.portal_profile_url_approved) && (
                      <Card href="#">
                        <p className="font-normal text-gray-700 dark:text-gray-400" />
                        <div className="mb-2 block">
                          <Label htmlFor="profile_img" value="Profile" />
                        </div>
                        <img
                          id="profile_img"
                          width="300px"
                          src={
                            newImageUrl
                              ? newImageUrl
                              : member.portal_profile_url_pending
                              ? member.portal_profile_url_pending
                              : member.portal_profile_url_approved
                          }
                        ></img>
                      </Card>
                    )}
                  </div>
                )}
              </div>
            ))}

          {!phoneValid && (
            <Alert color="failure" icon={HiInformationCircle}>
              Please enter a valid 9 or 10 digit phone number
            </Alert>
          )}

          {dirty && emailvalid && phoneValid && (
            <Button onClick={handleUpdate}>
              Update IATSE Local With These Values
            </Button>
          )}

          {updateFailed && (
            <Alert color="failure" icon={HiInformationCircle}>
              {errorMessage}
            </Alert>
          )}

          {loading && <Spinner color="success" />}
          {updateOk && (
            <Toast>
              <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
                <HiCheck className="h-5 w-5" />
              </div>
              <div className="ml-3 text-sm font-normal">Updates completed.</div>
              <Toast.Toggle />
            </Toast>
          )}
        </form>
      )}
    </div>
  );
};

export default MemberInfoPage;
