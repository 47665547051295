import apiServices from './apiServices';
import { getAvailableReq,setAvailableReq,setAvailForDate }  from './dtos';



class availServices  {
    

     baseSvc = apiServices;
     client = this.baseSvc.client;

   
    constructor() {
     
      }

      

      setavailability(avails : setAvailForDate[])
      {
        
        const request = new setAvailableReq();
        request.availDates = avails;
        

        return this.client.put(request);

      }
      
    getAvailability()
    {
        
        
        const request = new getAvailableReq();

        return this.client.get(request);


    }
    

}




export default availServices;
