import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../lib';
import apiServices from '../../services/apiServices';
import { local_member_ui, PortalPaymentDisplay } from '../../services/dtos';
import memberInfoServices from '../../services/memberInfoSvcs';
import pmtServices from '../../services/pmtServices';

const DuesReceiptPage: FC = () => {
  const { sessionid } = useParams();

  const [member, setMember] = useState<local_member_ui>(new local_member_ui());

  const [payment, setPayment] = useState<PortalPaymentDisplay>();
  const [localName, setLocalName] = useState<string>('');

  const Print = () => {
    //console.log('print');
    // let printContents = document.getElementById('printablediv').innerHTML;
    // let originalContents = document.body.innerHTML;
    //  document.body.innerHTML = printContents;
    window.print();
    // document.body.innerHTML = originalContents;
  };

  let svc2 = apiServices;

  let navigate = useNavigate();

  let failed = false;

  useEffect(() => {
    if (!payment && !failed) {
      let msvc = new memberInfoServices();
      msvc.getMemberInfo().then((data) => {
        setMember(data);

        console.log(data);
      });

      let svc = new pmtServices();

      svc.getLocalName().then((data) => {
        setLocalName(data);
      });

      svc.getPayment(sessionid).then((data) => {
        setPayment(data);
      });
    }
  });

  return (
    <div>
      <div
        id="printablediv"
        className="mx-auto w-full flex max-w-4xl flex-col gap-8  dark:text-gray-400"
      >
        <img
          width="150"
          src="https://laborwrx.com/cdn/shop/collections/iatse122.png"
        />

        <span className="text-2xl font-bold">
          {localName} Member Dues Stamp Receipt
        </span>

        {member && payment && (
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="py-3 px-6">
                  Member
                </th>
                <th scope="col" className="py-3 px-6">
                  {member.first_name} {member.last_name}
                </th>
              </tr>
              <tr>
                <th scope="col" className="py-3 px-6">
                  Payment Date
                </th>

                <th scope="col" className="py-3 px-6">
                  {payment.completed_at_display}
                </th>
              </tr>
              <tr>
                <th scope="col" className="py-3 px-6">
                  Amt Paid
                </th>

                <th scope="col" className="py-3 px-6">
                  {payment.amts_paid}
                </th>
              </tr>
              <tr>
                <th scope="col" className="py-3 px-6">
                  Items Paid
                </th>

                <th scope="col" className="py-3 px-6">
                  {payment.items_paid_display}
                </th>
              </tr>
            </thead>
          </table>
        )}
      </div>

      <table>
        <tbody>
          <tr>
            <td>
              <Button onClick={() => navigate('/dues')}>Go Back To Dues</Button>
            </td>
            <td>
              <Button onClick={Print}>Print</Button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DuesReceiptPage;
