'use client';

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from '@headlessui/react';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { BiDollar } from 'react-icons/bi';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { HiClipboardList, HiOutlineDocumentReport } from 'react-icons/hi';
import {
  MdClose,
  MdEditCalendar,
  MdLogin,
  MdLogout,
  MdMap,
  MdMenu,
  MdOutlineFeedback,
  MdOutlineSms,
  MdOutlineTimeline,
  MdOutlineSchool,
} from 'react-icons/md';
import { RiUserSettingsLine } from 'react-icons/ri';
import { TbUsers } from 'react-icons/tb';
import { Link, useLocation } from 'react-router-dom';
import { DarkThemeToggle } from '../lib';
import {
  RequireAuthenticated,
  RequireUnAuthenticated,
  useAuth,
} from './providers/auth';
import {
  ROLE_ADMIN,
  ROLE_BETA,
  ROLE_OFFICE_PMTS,
  ROLE_STEWARD_TEXT,
} from './Root';

type NavigationItem = {
  name: string;
  href: string;
  current: boolean;
  requiresAuth: boolean;
  roles?: string[]; // Optional array of roles
  external?: boolean; // Optional flag for external links
  onClick?: () => void; // Optional onClick handler
  icon?: typeof MdLogin;
};

const navigation = (pathname: string): NavigationItem[] => [
  {
    name: 'Login',
    href: '/login',
    current: pathname === '/login',
    requiresAuth: false,
    icon: MdLogin,
  },
  {
    name: 'Register',
    href: '/register',
    current: pathname === '/register',
    requiresAuth: false,
    icon: AiOutlineUserAdd,
  },
  {
    name: 'Our Venues',
    href: 'https://www.google.com/maps/d/u/0/viewer?hl=en&ll=32.754887525686385%2C-117.14119669492595&z=12&mid=1xwQY93-0vYb75F0OtnAV-PX0SsrgPaZe',
    current: false,
    requiresAuth: true,
    external: true,
    icon: MdMap,
  },
  {
    name: 'My Availability',
    href: '/avail',
    current: pathname === '/avail',
    requiresAuth: true,
    icon: MdEditCalendar,
  },
  {
    name: 'Membership Info',
    href: '/info',
    current: pathname === '/info',
    requiresAuth: true,
    icon: RiUserSettingsLine,
  },
  {
    name: 'Job History',
    href: '/jobhist',
    current: pathname === '/jobhist',
    requiresAuth: true,
    icon: HiClipboardList,
  },
  {
    name: 'Wage History',
    href: '/wagehist',
    current: pathname === '/wagehist',
    requiresAuth: true,
    icon: HiOutlineDocumentReport,
  },
  
  {
    name: 'Office Pmts',
    href: '/dues-admin',
    current: pathname === '/dues-admin',
    requiresAuth: true,
    roles: [ROLE_OFFICE_PMTS, ROLE_ADMIN],
    icon: TbUsers,
  },
  {
    name: 'Timesheet',
    href: '/timesheet',
    current: pathname === '/timesheet',
    requiresAuth: true,
    roles: [ROLE_BETA, ROLE_ADMIN],
    icon: MdOutlineTimeline,
  },
  {
    name: 'Payments',
    href: '/dues',
    current: pathname === '/dues',
    requiresAuth: true,
    icon: BiDollar,
  },
  {
    name: 'Text Members On Job',
    href: '/text-members-on-job',
    current: pathname === '/text-members-on-job',
    requiresAuth: true,
    roles: [ROLE_STEWARD_TEXT, ROLE_ADMIN],
    icon: MdOutlineSms,
  },
  {
    name: 'Skills',
    href: '/skills',
    current: pathname === '/skills',
    requiresAuth: true,
    roles: [ROLE_ADMIN],
    icon: MdOutlineSchool,
  },
  {
    name: 'Feedback',
    href: '/feedback',
    current: pathname === '/feedback',
    requiresAuth: true,
    icon: MdOutlineFeedback,
  },
];

function DashboardLayout({ children }: React.PropsWithChildren<{}>) {
  const auth = useAuth();
  const { pathname } = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    setSidebarOpen(false);
  }, [pathname]);

  return (
    <>
      {sidebarOpen && (
        <Dialog
          open={sidebarOpen}
          onClose={setSidebarOpen}
          className="relative z-50 lg:hidden"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 dark:bg-gray-800/70 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel className="relative animate-in slide-in-from-left-full mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:slide-out-to-left">
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                    className="-m-2.5 p-2.5"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <MdClose
                      aria-hidden="true"
                      className="h-6 w-6 text-white"
                    />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white dark:bg-gray-900 px-6 pb-2">
                <div className="flex h-16 shrink-0 items-center">
                <div  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">IATSE 122 Portal</div>
                  <div className="flex flex-1 justify-end">
                    <DarkThemeToggle />
                  </div>
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation(pathname).map((item) => (
                          <NavItem key={item.name} item={item} />
                        ))}
                      </ul>
                    </li>
                    {auth.isLoggedIn && (
                      <li className="-mx-6 mt-auto">
                        <button
                          className="flex w-full items-center text-red-500 gap-x-4 px-6 py-3 text-sm font-semibold leading-6  hover:bg-gray-50 dark:hover:bg-gray-700"
                          onClick={auth.signOut}
                        >
                          <MdLogout className=" w-6 h-6" />
                          Logout
                        </button>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      )}

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r dark:border-none border-gray-200 bg-white dark:bg-gray-900 px-6">
          <div className="flex h-16 shrink-0 items-center">
          <div   className="text text-gray-700 uppercase bg-gray-50 dark:bg-gray-900 dark:text-gray-400">IATSE 122 Portal</div>
            <div className="ml-auto -mr-3">
              <DarkThemeToggle />
            </div>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation(pathname).map((item) => (
                    <NavItem key={item.name} item={item} />
                  ))}
                </ul>
              </li>

              {auth.isLoggedIn && (
                <li className="-mx-6 mt-auto ">
                  <button
                    className="flex w-full items-center text-red-500 gap-x-4 px-6 py-3 text-sm font-semibold leading-6  hover:bg-gray-50 dark:hover:bg-gray-700"
                    onClick={auth.signOut}
                  >
                    <MdLogout className=" w-6 h-6" />
                    Logout
                  </button>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6 dark:bg-gray-900 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          onClick={() => setSidebarOpen(true)}
          className="-m-2.5 p-2.5 text-gray-700 dark:text-gray-100 lg:hidden"
        >
          <span className="sr-only">Open sidebar</span>
          <MdMenu aria-hidden="true" className="h-6 w-6" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-gray-900 dark:text-white">
          Dashboard
        </div>
        <div className="-my-2">
        <div   className="text text-gray-700 uppercase bg-gray-50 dark:bg-gray-900 dark:text-gray-400">IATSE 122 Portal</div>
          <DarkThemeToggle />
        </div>
        {auth.isLoggedIn && (
          <button onClick={auth.signOut}>
            <MdLogout className=" w-6 h-6 text-red-500" />
          </button>
        )}
      </div>

      <main className="py-10 grid lg:pl-[20rem] flex-1 px-6 bg-white dark:bg-gray-800">
        {children}
      </main>
    </>
  );
}

function NavItem({ item }: { item: NavigationItem }) {
  const render = () => (
    <div
      className={classNames(
        item.current
          ? 'bg-gray-50 dark:bg-gray-700 text-indigo-600 dark:text-white'
          : 'text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:text-indigo-600 dark:hover:text-white dark:hover:bg-gray-800',
        'group items-center flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
      )}
    >
      {item.icon && (
        <item.icon
          aria-hidden="true"
          className={classNames(
            item.current
              ? 'text-indigo-600 dark:text-gray-200'
              : 'text-gray-400 dark:text-gray-400 dark:group-hover:text-gray-300 group-hover:text-indigo-600 ',
            'h-6 w-6 shrink-0'
          )}
        />
      )}
      {item.name}
      {item.external && (
        <div className="flex-1 flex justify-end">
          <BsBoxArrowUpRight className="text-inherit w-4 h-4" />
        </div>
      )}
    </div>
  );
  const Comp = item.requiresAuth
    ? RequireAuthenticated
    : RequireUnAuthenticated;

  const children = (
    <>
      {item.requiresAuth && (
        <RequireAuthenticated redirect={false} roles={item.roles}>
          {render()}
        </RequireAuthenticated>
      )}
      {!item.requiresAuth && (
        <RequireUnAuthenticated redirect={false}>
          {render()}
        </RequireUnAuthenticated>
      )}
    </>
  );
  return (
    <Comp key={item.name} redirect={false}>
      <li>
        {item.external ? (
          <a href={item.href} target="_blank">
            {children}
          </a>
        ) : (
          <Link to={item.href}>{children}</Link>
        )}
      </li>
    </Comp>
  );
}
export { DashboardLayout };
