import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Datepicker from 'react-tailwindcss-datepicker';
import { Button, Modal, Spinner } from '../../lib';
import apiServices from '../../services/apiServices';
import {
  local_member_ui,
  WageStatement,
  WageStatementResponse,
} from '../../services/dtos';
import jobServices from '../../services/jobServices';
import memberInfoServices from '../../services/memberInfoSvcs';

const WageHistoryPage: FC = () => {
  const [member, setMember] = useState<local_member_ui>(new local_member_ui());

  const [wageHist, setWageHist] = useState<Array<WageStatementResponse>>();
  const [loading, setLoading] = useState(false);
  const [wageDetails, setWageDetails] = useState<
    Array<WageStatement> | undefined
  >(undefined);

  const [dateRange, setdateRange] = useState({
    startDate: new Date(new Date().getFullYear(), 0),
    endDate: new Date(new Date().getFullYear(), 11, 31),
  });

  let svc2 = apiServices;

  let navigate = useNavigate();

  let failed = false;

  const [baseUrl, setbaseUrl] = useState<string>(svc2.apiUrl());

  const downloadPdf = function () {
    const dtStart =
      dateRange.startDate instanceof Date
        ? dateRange.startDate.toISOString()
        : dateRange.startDate;
    const dtEnd =
      dateRange.endDate instanceof Date
        ? dateRange.endDate.toISOString()
        : dateRange.endDate;

    window.open(
      baseUrl + '/member/wagestatementpdf/' + dtStart + '/' + dtEnd,
      '_blank'
    );
  };
  const handleDateChange = (newValue) => {
    console.log('newValue:', newValue);
    setdateRange(newValue);
    let svc = new jobServices();
    setLoading(true);
    setWageHist([]);

    svc.getWageHistory(newValue.startDate, newValue.endDate).then((data) => {
      setWageHist(data);
      setLoading(false);
    });
  };

  const showDeets = function (ix: number) {
    if (ix < 0) {
      setWageDetails(undefined);
    } else {
      setWageDetails(wageHist[ix].wage_recs);
      console.log(wageHist[ix].wage_recs);
    }
  };

  useEffect(() => {
    if (!wageHist && !loading && !failed) {
      setLoading(true);

      let msvc = new memberInfoServices();
      msvc.getMemberInfo().then((data) => {
        setMember(data);
        console.log(data);
      });

      let svc = new jobServices();

      svc
        .getWageHistory(
          dateRange.startDate.toISOString(),
          dateRange.endDate.toISOString()
        )
        .then((data) => {
          setWageHist(data);

          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          if (
            e.type == 'RefreshTokenException' ||
            e.responseStatus.errorCode == '403' ||
            e.responseStatus.errorCode == '401'
          ) {
            navigate('/login');
          }
          if (
            e.responseStatus.errorCode == '500' ||
            e.responseStatus.errorCode == '404'
          ) {
            failed = false;
          }
        });
    }
  });

  return (
    <div className="mx-auto w-full flex max-w-4xl flex-col gap-6  dark:text-gray-400">
      <div>
        <Datepicker value={dateRange} onChange={handleDateChange} />
      </div>
      Please call Local 122 to report any discrepancies
      <Button color="info" onClick={downloadPdf}>
        Download PDF
      </Button>
      <span className="text-2xl font-bold">Wage History</span>
      {loading && <Spinner color="success" />}
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="py-3 px-6">
              Employer
            </th>

            <th scope="col" className="py-3 px-6">
              Dates
            </th>
          </tr>
        </thead>
        <tbody>
          {wageHist &&
            wageHist.length > 0 &&
            wageHist.map(
              ({ employer, firstDt_display, lastDt_display }, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                >
                  <td className="py-4 px-6">
                    <a
                      href="#"
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      onClick={() => {
                        showDeets(index);
                      }}
                    >
                      <span>
                        {employer}
                        <svg
                          data-accordion-icon
                          className="w-3 h-3 rotate-180 shrink-0"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M9 5 5 1 1 5"
                          />
                        </svg>
                      </span>
                    </a>
                  </td>
                  <td className="py-4 px-6">
                    {firstDt_display}-{lastDt_display}
                  </td>
                </tr>
              )
            )}
        </tbody>
      </table>
      {setWageDetails && (
        <Modal
          show={wageDetails && wageDetails.length > 0}
          onClose={() => setWageDetails(undefined)}
        >
          <Modal.Header>Wage Details</Modal.Header>
          <Modal.Body>
            <table className="w-full text-xs text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr className="text-xs font-medium ">
                  <td>Site</td>
                  <td>Show</td>
                  <td>Pay Date</td>
                  <td>Work Fee Owed</td>
                  <td>Work Fee Paid</td>
                  <td>Gross Owed</td>
                  <td>Dues Paid</td>
                </tr>
              </thead>
              <tbody>
                {wageDetails &&
                  wageDetails.length > 0 &&
                  wageDetails.map(
                    (
                      {
                        site,
                        show,
                        gross_Owed,
                        work_Fee_Owed,
                        work_Fee_Paid,
                        payDate,
                        duesPaid,
                      },
                      index
                    ) => (
                      <tr
                        key={index}
                        className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                      >
                        <td>{site}</td>
                        <td>{show}</td>
                        <td>{payDate.substring(0, 10)}</td>
                        <td>{Math.round(work_Fee_Owed * 100) / 100}</td>
                        <td>{Math.round(work_Fee_Paid * 100) / 100}</td>
                        <td>{Math.round(gross_Owed * 100) / 100}</td>
                        <td>{duesPaid ? 'Yes' : 'No'}</td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button color="gray" onClick={() => setWageDetails(undefined)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      ;{wageDetails && wageDetails.length}
    </div>
  );
};

export default WageHistoryPage;
