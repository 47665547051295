import { Suspense, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Spinner } from '../lib';
import { DashboardLayout } from './DashboardLayout';
import AdminDuesPaymentPage from './pages/AdminDuesPaymentsPage';
import DuesPmtPage from './pages/DuesPaymentsPage';
import DuesPmtSuccessPage from './pages/DuesPmtSuccessPage';
import DuesReceiptPage from './pages/DuesReceipt';
import FeedbackPage from './pages/FeedbackPage';
import JobHistoryPage from './pages/JobHistoryPage';
import JobOfferPage from './pages/JobOfferPage';
import LoginPage from './pages/LoginPage';
import MemberAvailabilityPage from './pages/MemberAvailabilityPage';
import MemberInfoPage from './pages/MemberInfoPage';
import RegisterPage from './pages/RegisterPage';
import TextMembersOnJob from './pages/TextMembersonJob';
import { TimesheetPage } from './pages/TimesheetPage';
import WageHistoryPage from './pages/WageHistoryPage';
import MemberSkillsPage from  './pages/MemberSkillsPage';
import {
  RequireAuthenticated,
  RequireUnAuthenticated,
  useAuth,
} from './providers/auth';

export const ROLE_ADMIN = 'Admin';
export const ROLE_BETA = 'Beta';
export const ROLE_OFFICE_PMTS = 'Office_Pmts';
export const ROLE_STEWARD_TEXT = 'Steward_Text';

export const Root = () => {
  const auth = useAuth();
  const [navbarCollapse, setnavbarCollapse] = useState(false);

  const handleNavBarClick = () => {
    setnavbarCollapse(!navbarCollapse);
  };

  const { pathname } = useLocation();

  return (
    <DashboardLayout>
      <Suspense
        fallback={
          <div className="flex h-full items-center justify-center">
            <Spinner />
          </div>
        }
      >
        <Routes>
          <Route
            key="/login"
            path="/login"
            element={
              <RequireUnAuthenticated redirect>
                <LoginPage />
              </RequireUnAuthenticated>
            }
          />
          <Route
            key="/register"
            path="/register"
            element={
              <RequireUnAuthenticated redirect>
                <RegisterPage />
              </RequireUnAuthenticated>
            }
          />

          <Route
            path="/info"
            element={
              <RequireAuthenticated redirect>
                <MemberInfoPage />
              </RequireAuthenticated>
            }
          />

          <Route
            path="/jobhist"
            element={
              <RequireAuthenticated redirect>
                <JobHistoryPage />
              </RequireAuthenticated>
            }
          />

          <Route
            path="/wagehist"
            element={
              <RequireAuthenticated redirect>
                <WageHistoryPage />
              </RequireAuthenticated>
            }
          />

          <Route
            path="/payment-success/:sessionid"
            element={
              <RequireAuthenticated redirect>
                <DuesPmtSuccessPage />
              </RequireAuthenticated>
            }
          />

          <Route
            path="/dues-admin"
            element={
              <RequireAuthenticated
                redirect
                roles={[ROLE_OFFICE_PMTS, ROLE_ADMIN]}
              >
                <AdminDuesPaymentPage />
              </RequireAuthenticated>
            }
          />

          <Route
            path="/dues-receipt/:sessionid"
            element={
              <RequireAuthenticated redirect>
                <DuesReceiptPage />
              </RequireAuthenticated>
            }
          />

          <Route
            path="/dues"
            element={
              <RequireAuthenticated redirect>
                <DuesPmtPage />
              </RequireAuthenticated>
            }
          />

          <Route
            path="/timesheet"
            element={
              <RequireAuthenticated redirect roles={[ROLE_BETA, ROLE_ADMIN]}>
                <TimesheetPage />
              </RequireAuthenticated>
            }
          />

          <Route
            path="/avail"
            element={
              <RequireAuthenticated redirect>
                <MemberAvailabilityPage />
              </RequireAuthenticated>
            }
          />


        <Route
            path="/skills"
            element={
              <RequireAuthenticated redirect>
                <MemberSkillsPage />
              </RequireAuthenticated>
            }/>

          <Route
            path="/feedback"
            element={
              <RequireAuthenticated redirect>
                <FeedbackPage />
              </RequireAuthenticated>
            }
          />

          <Route path="/offer/:shortCode" element={<JobOfferPage />} />

          <Route path="/o/:shortCode" element={<JobOfferPage />} />

          <Route
            path="/"
            element={
              <RequireAuthenticated redirect>
                <MemberInfoPage />
              </RequireAuthenticated>
            }
          />

          <Route
            path="/text-members-on-job"
            element={
              <RequireAuthenticated
                redirect
                roles={[ROLE_STEWARD_TEXT, ROLE_ADMIN]}
              >
                <TextMembersOnJob />
              </RequireAuthenticated>
            }
          />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </DashboardLayout>
  );
};
