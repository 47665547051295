import { useState } from 'react';
import { HiInformationCircle } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Label, Spinner, TextInput } from '../../lib';
import jobServices from '../../services/jobServices';
import { useAuth } from '../providers/auth';

const LoginPage = () => {
  const [username, setuserName] = useState<string>('');
  const [password, setpassword] = useState<string>('');
  const [loginfailed, setloginfailed] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false);

  const auth = useAuth();
  let navigate = useNavigate();

  function handleLogin() {
    setloading(true);

    auth
      .signIn(username, password)
      .then(() => {
        setloading(false);
        let jsvc = new jobServices();

        //this is for caching.
        jsvc.getJobHistory();

        navigate('/avail');
      })
      .catch((e) => {
        console.log(e);
        setloading(false);
        setloginfailed(true);
      });
  }

  function handleRegister() {
    navigate('/register');
  }

  return (
    <div className="mx-auto w-full flex max-w-2xl flex-col gap-8 dark:text-white">
      <span className="text-2xl font-bold">Login</span>
      <form className="flex flex-col gap-4">
        <div>
          <div className="mb-2 block">
            <Label htmlFor="email1" value="Your email" />
          </div>
          <TextInput
            id="email1"
            type="email"
            placeholder="user@domain.com"
            required
            value={username}
            onChange={(e) => setuserName(e.target.value)}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="password1" value="Your password" />
          </div>
          <TextInput
            id="password1"
            type="password"
            required
            value={password}
            onChange={(e) => setpassword(e.target.value)}
          />
        </div>

        {!loading && <Button onClick={handleLogin}>Login</Button>}

        {loading && <Spinner color="success" />}

        {!loading && <Button onClick={handleRegister}>Register as New User</Button>}
        {!loading && <Button onClick={handleRegister}>Forgot Password</Button>}
        

        {loginfailed && (
          <Alert color="failure" icon={HiInformationCircle}>
            Login Failed
          </Alert>
        )}
      </form>
    </div>
  );
};

export default LoginPage;
