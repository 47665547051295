import { FC,useEffect } from 'react';
import { Spinner,Tooltip ,Button,Alert,Card} from '../../lib';
import pmtServices  from '../../services/pmtServices';
import apiServices from '../../services/apiServices';
import { useState } from 'react';
import { useNavigate , useParams,Link} from "react-router-dom";



const DuesPmtSuccess: FC = () => {


  const { sessionid } = useParams();

  console.log(sessionid);

  let navigate = useNavigate();

  const [msg, setMsg] = useState<string>("Please wait, finalizing");
  
  let startedToProcess = false;


  useEffect(() => {
  
    if(startedToProcess)
    {
      return;
    }
    startedToProcess = true;

    let svc = new pmtServices();
    svc.finishPayment(sessionid).then((data)=>
    {
        if(data.message.startsWith("SUCCESS"))
        {
          setMsg("Payment Successful");
          

        }
        else
        {
          setMsg(data.message);
        }

  
    });
  

  
});

  
  
  const [loading, setLoading] = useState(false);


  let svc2 = apiServices;
  const [baseUrl, setbaseUrl] = useState<string>(svc2.apiUrl());

  

  return (

    <div>  
       {msg!="Please wait, finalizing" && msg!="Payment Successful" && 
      <Card>
       <Alert color="error">
    <span className="font-medium"></span> {msg}
  </Alert>
  </Card>}


      {msg=="Payment Successful" && 
      <Card>
       <Alert color="info">
    <span className="font-medium"></span> {msg}
  </Alert>

  <hr/>


    <Button onClick={() => navigate("/dues-receipt/" + sessionid)}>View Receipt</Button>
    </Card>}

    <hr/>


    <Card><Button onClick={() => navigate("/dues")}>Go Back To Dues</Button></Card>

   </div>



  
      );




};

export default DuesPmtSuccess;
