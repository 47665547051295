import apiServices from './apiServices';
import {
  addMemberToStewReport,
  getDepartments,
  getJobsForSteward,
  getmemberjobHistReq,
  getMembersForJob,
  getStewDetailRecordsForJob,
  getWageStatementReequest,
  massTextFromStewReport,
  removeMemberFromStewReport,
  saveStewDetailRecs,
  saveStewHeaderReq,
  searchMember,
  SendJobMembersSMS,
  StewardReportDetail_ui,
} from './dtos';

class jobServices {
  baseSvc = apiServices;
  client = this.baseSvc.client;

  constructor() {}

  getJobHistory_older() {
    const request = new getmemberjobHistReq();
    request.skip = 10;
    request.take = 300;
    request.startDate = '2021-01-01';
    request.endDate = '2100-01-01';

    return this.client.get(request);
  }

  getJobHistory(params: Partial<getmemberjobHistReq> = {}) {
    const request = new getmemberjobHistReq({
      skip: 0,
      take: 10,
      startDate: '2021-01-01',
      endDate: '2100-01-01',
      ...params,
    });

    return this.client.get(request);
  }

  getWageHistory(startDate: string, endDate: string) {
    const request = new getWageStatementReequest();
    request.startDate = startDate;
    request.endDate = endDate;

    return this.client.get(request);
  }

  getJobsForSteward() {
    const request = new getJobsForSteward();
    return this.client.get(request);
  }

  getMembersForJob(job_id: string) {
    return this.client.get(new getMembersForJob({ job_id }));
  }

  sendJobMembersSMS(data: Partial<SendJobMembersSMS>) {
    return this.client.post(new SendJobMembersSMS(data));
  }

  saveStewHeader(
    StewardReportHeaderId: number,
    other_rate_1: number,
    other_rate_2: number
  ) {
    const request = new saveStewHeaderReq();
    request.stewardReportHeaderId = StewardReportHeaderId;
    request.other_rate_1 = other_rate_1;
    request.other_rate_2 = other_rate_2;

    return this.client.put(request);
  }

  getStewardDetailedRecordsForJob(jobId: string, reportDate: string) {
    const request = new getStewDetailRecordsForJob();
    request.job_id = jobId;
    request.report_date = reportDate;
    return this.client.get(request);
  }

  getDepartments() {
    const request = new getDepartments();
    return this.client.get(request);
  }

  saveDetailedJobRecords(
    detailedJobRecords: StewardReportDetail_ui[],
    finalSubmit?: boolean
  ) {
    const request = new saveStewDetailRecs();
    request.detail_records = detailedJobRecords;
    for (var i = 0; i < request.detail_records.length; i++) {
      if (request.detail_records[i].in_time_1 == 'PT0S')
        delete request.detail_records[i].in_time_1;
      if (request.detail_records[i].in_time_2 == 'PT0S')
        delete request.detail_records[i].in_time_2;
      if (request.detail_records[i].in_time_3 == 'PT0S')
        delete request.detail_records[i].in_time_3;
      if (request.detail_records[i].out_time_1 == 'PT0S')
        delete request.detail_records[i].out_time_1;
      if (request.detail_records[i].out_time_2 == 'PT0S')
        delete request.detail_records[i].out_time_2;
      if (request.detail_records[i].out_time_3 == 'PT0S')
        delete request.detail_records[i].out_time_3;
    }
    if (finalSubmit) {
      console.log('Performing a final submit');
      request.final_submit = finalSubmit;
    }
    return this.client.put(request);
  }

  searchMember(searchTerm: string) {
    const request = new searchMember();
    request.search_term = searchTerm;
    return this.client.get(request);
  }

  addMember(
    jobId: string,
    stewardReportHeaderId: number,
    uniqueMemberId: string
  ) {
    const request = new addMemberToStewReport();
    request.job_ID = jobId;
    request.stewardReportHeaderId = stewardReportHeaderId;
    request.unique_member_id = uniqueMemberId;
    return this.client.post(request);
  }

  updateHeader(
    stewardReportHeaderId: number,
    other_rate_1: number,
    other_rate_2: number
  ) {
    const request = new saveStewHeaderReq();
    request.stewardReportHeaderId = stewardReportHeaderId;
    request.other_rate_1 = other_rate_1;
    request.other_rate_2 = other_rate_2;

    return this.client.put(request);
  }

  removeMember(id: number) {
    const request = new removeMemberFromStewReport();
    request.id = id;
    console.log('Deleting ' + id);
    return this.client.delete(request);
  }

  massTextFromStewReport(
    jobId: string,
    smsMessage: string,
    uniqueMemberIds: string[]
  ) {
    const request = new massTextFromStewReport();
    request.job_ID = jobId;
    request.sms_message = smsMessage;
    request.unique_member_ids = uniqueMemberIds;
    return this.client.post(request);
  }
}

export default jobServices;
