import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { HiInformationCircle } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Label, Spinner, TextInput } from '../../lib';
import apiServices from '../../services/apiServices';
import { useAuth } from '../providers/auth';

const RegisterPage = () => {
  const auth = useAuth();

  const [loading, setloading] = useState<boolean>(false);
  const [email, setemail] = useState<string>('');
  const [password, setpassword] = useState<string>('');
  const [invite_code, setinviteCode] = useState<string>('');
  const [verification_code, setverificationCode] = useState<string>('');
  const [captchaValue, setcaptchaValue] = useState<string>('');

  const [weakPassword, setWeakPassword] = useState(false);
  const [badInviteCode, setbadInviteCode] = useState(false);

  const [loginfailed, setloginfailed] = useState<boolean>(false);

  let navigate = useNavigate();

  const updateInviteCode = (value: string) => {
    value = value.trim();

    if (value.length != 4) {
      setbadInviteCode(true);
    } else {
      let is4numbers = /\d\d\d\d/.test(value);
      setbadInviteCode(!is4numbers);
    }

    setinviteCode(value);
  };

  const updatePassword = (value: string) => {
    value = value.trim();

    if (value.length < 8) {
      setWeakPassword(true);
    } else {
      let hasUpperCase = /[A-Z]/.test(value) ? 1 : 0;
      let hasLowerCase = /[a-z]/.test(value) ? 1 : 0;
      let hasNumbers = /\d/.test(value) ? 1 : 0;
      let hasNonalphas = /\W/.test(value) ? 1 : 0;
      if (
        hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas < 4 ||
        value == 'caNtguess5%'
      ) {
        setWeakPassword(true);
      } else {
        setWeakPassword(false);
      }
    }

    setpassword(value);
  };

  function ReCAPTCHAChange(value: string) {
    setcaptchaValue(value);
  }

  function handleLogin() {
    navigate('/login');
  }

  function handleRegister() {
    setloginfailed(false);
    setloading(true);
    let svc = apiServices;

    svc
      .register(email, password, invite_code, verification_code, captchaValue)
      .then(() => {
        auth.signIn(email, password);
        svc.login(email, password).then(() => {
          setloading(false);
        });
      })
      .catch((e) => {
        console.error(e);
        setloading(false);
        setloginfailed(true);
      });
  }

  return (
    <div className="mx-auto w-full flex max-w-2xl flex-col gap-8 dark:text-white">
      <span className="text-2xl font-bold">
        Register (or Forgot Username/Password)
      </span>

      <form className="flex flex-col gap-4">
        <div>
          <div className="mb-2 block">
            <Label htmlFor="email1" value="Your email" />
          </div>
          <TextInput
            id="email1"
            type="email"
            placeholder="user@domain.com"
            required
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="password1"
              value="Your password - Example: caNtguess5%"
            />
          </div>
          <TextInput
            id="password1"
            type="password"
            required
            value={password}
            onChange={(e) => updatePassword(e.target.value)}
          />
        </div>
        {weakPassword && (
          <Alert color="failure" icon={HiInformationCircle}>
            Please choose a more complex pasword (8+ length, Uppercase +
            Lowercase + Number + Symbol)
          </Alert>
        )}

        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="invite_code"
              value="Month and Day of your birthdate (ex: 0531)"
            />
          </div>
          <TextInput
            id="invite_code"
            type="text"
            required
            value={invite_code}
            onChange={(e) => updateInviteCode(e.target.value)}
          />
        </div>
        {badInviteCode && (
          <Alert color="failure" icon={HiInformationCircle}>
            Please enter birth month and day (ex: 0515 for May 15)
          </Alert>
        )}

        <div>
          <div className="mb-2 block">
            <Label htmlFor="verification_code" value="Last 4 SSN" />
          </div>
          <TextInput
            id="verification_code"
            type="password"
            required
            value={verification_code}
            onChange={(e) => setverificationCode(e.target.value)}
          />
        </div>

        {!loading &&
          !weakPassword &&
          verification_code.length == 4 &&
          invite_code.length == 4 && (
            <ReCAPTCHA
              sitekey="6LctawcTAAAAACuZMpmt0Yy8tfb7gNHDdGGgAiXu"
              onChange={ReCAPTCHAChange}
            />
          )}

        {!loading &&
          !weakPassword &&
          captchaValue.length > 10 &&
          verification_code.length == 4 &&
          invite_code.length == 4 && (
            <Button onClick={handleRegister}>Register</Button>
          )}
        {loading && <Spinner color="success" />}
        <Button onClick={handleLogin}>Already registered? - Login</Button>

        {loginfailed && (
          <Alert color="failure" icon={HiInformationCircle}>
            Registration Failed, please check your details and try again.
          </Alert>
        )}
      </form>
    </div>
  );
};

export default RegisterPage;
