interface FinalSubmitButtonProps {
  areRecordsValid: boolean;
  alreadySubmitted: boolean;
  submitValidClassList: string;
  submitInvalidClassList: string;
  onClick: () => void;
}
export const FinalSubmitButton = ({
  areRecordsValid,
  alreadySubmitted,
  submitValidClassList,
  submitInvalidClassList,
  onClick,
}: FinalSubmitButtonProps) => {
  return (
    <button
      style={{ width: "15rem" }}
      id="final-submit"
      disabled={!areRecordsValid || alreadySubmitted}
      onClick={onClick}
      className={
        areRecordsValid || alreadySubmitted
          ? submitValidClassList
          : submitInvalidClassList
      }
    >
      Final Submit
    </button>
  );
};
