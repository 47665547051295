import apiServices from './apiServices';
import { getlocalmemberReq, local_member_ui, updateBioDataReq ,feedbackreq, getMemberSkills}  from './dtos';



class memberInfoServices  {
    

     baseSvc = apiServices;
     client = this.baseSvc.client;

   
    constructor() {
     
      }


      getMemberSkills()
      {
        const request = new getMemberSkills();
          
  
        return this.client.get(request)
  
      }
    
    getMemberInfo()
    {
      const request = new getlocalmemberReq();
        

      return this.client.get(request)

    }


    updateMemberFeedback(feedback : string)
    {
       
     
      const req =new feedbackreq();
      req.feedback = feedback;

  
        return this.client.patch(req);


    }
      
    
    updateMemberInfo(member : local_member_ui)
    {
       
     
      const req = new updateBioDataReq();
      req.member = member;
        return this.client.put(req);


    }
    

}




export default memberInfoServices;
