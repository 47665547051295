import { useCallback, useEffect, useRef, useState } from 'react';

type Status = 'idle' | 'pending' | 'success' | 'error';

interface AsyncState<T> {
  status: Status;
  value: T | null;
  error: Error | null;
}
interface Flags {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}
interface UseAsyncReturn<T, Args extends any[]> extends AsyncState<T> {
  execute: (...args: Args) => Promise<void>;
  reset(): void;
}

function useAsync<T, Args extends any[]>(
  asyncFunction: (...args: Args) => Promise<T>,
  immediate = true,
  initialArgs: Args
): UseAsyncReturn<T, Args> & Flags {
  const [state, setState] = useState<AsyncState<T>>({
    status: 'idle',
    value: null,
    error: null,
  });

  // Use useRef for the asyncFunction
  const asyncFunctionRef = useRef(asyncFunction);
  asyncFunctionRef.current = asyncFunction;

  const execute = useCallback((...args: Args): Promise<void> => {
    setState({ status: 'pending', value: null, error: null });

    return asyncFunctionRef
      .current(...args)
      .then((response) => {
        setState({ status: 'success', value: response, error: null });
      })
      .catch((error: Error) => {
        setState({ status: 'error', value: null, error });
      });
  }, []);
  const reset = useCallback(
    () =>
      setState({
        error: null,
        value: null,
        status: 'idle',
      }),
    []
  );
  useEffect(() => {
    if (immediate) {
      execute(...initialArgs);
    }
  }, [execute, immediate]);

  return {
    ...state,
    execute,
    reset,
    isLoading: state.status === 'pending',
    isSuccess: state.status === 'success',
    isError: state.status === 'error',
  };
}

export default useAsync;
