import React from "react";

interface MemberAddButtonProps {
  handleMemberAdd: () => void;
  memberToAdd: any;
  submitValidClassList: string;
  submitInvalidClassList: string;
}

export const MemberAddButton: React.FC<MemberAddButtonProps> = ({
  handleMemberAdd,
  memberToAdd,
  submitValidClassList,
  submitInvalidClassList,
}) => {
  const disabled = memberToAdd == undefined || !memberToAdd;
  const className = disabled
    ? submitInvalidClassList.replace("bg-blue-500", "bg-neutral-400")
    : submitValidClassList.replace("bg-blue-500", "bg-neutral-800");

  return (
    <button
      style={{ width: "15rem" }}
      onClick={handleMemberAdd}
      disabled={disabled}
      className={className}
    >
      Add member
    </button>
  );
};
