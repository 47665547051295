import type { FC } from 'react';
import { useState } from 'react';
import { HiCheck } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { Select, Spinner, Toast,Button,Badge } from '../../lib';
import availServices from '../../services/availSvcs';
import { member_available_dates,setAvailForDate } from '../../services/dtos';

const MemberAvailabilityPage: FC = () => {
  const [availList, setavailList] =
    useState<Array<member_available_dates>>(null);
  const [loading, setLoading] = useState(false);
  const [updateOk, setupdateOk] = useState(false);
  const [availabilityBulk, setavailabilityBulk] = useState("");
  let navigate = useNavigate();


  let applyBulkUpdates = function()
  {
    let availsToSend = new Array<setAvailForDate>();
    let newAvailState = new Array<member_available_dates>();

    for (var i = 0; i < availList.length; i++) {
      if(availList[i].selected)
      {
        let avail = new setAvailForDate();
        avail.availability_date = availList[i].availability_date;
        avail.availability_type = availabilityBulk;
        availsToSend.push(avail);
        availList[i].selected = false;
        newAvailState.push(availList[i]);

      }
    }

    setavailList(newAvailState);
    setavailabilityBulk("");

    let svc = new availServices();
    setLoading(true);
    document.getElementById('thetop')?.scrollIntoView({ behavior: 'smooth' });

    svc.setavailability(availsToSend).then(() => {
   
      let svc = new availServices();

      svc.getAvailability().then((data) => {
        setavailList(data);

        setupdateOk(true);
        setLoading(false);
      });
  });
}

  let setavailabilityBulkFromSelect = function(val :string)
  {
    setavailabilityBulk(val);
  }
  let updateSelected = function (
    ix: any,
    selected: boolean,
    
  ) {

    var newState = new Array<member_available_dates>();

    for (var i = 0; i < availList.length; i++) {
      if (ix == i) {
        availList[i].selected = selected;
      }

      newState.push(availList[i]);
    }

    setavailList(newState);
  };



  let selectAll = function (
   
    selected: boolean,
    
  ) {

    var newState = new Array<member_available_dates>();

    for (var i = 0; i < availList.length; i++) {
      availList[i].selected  = selected;
      
      newState.push(availList[i]);
    }

    setavailList(newState);
  };

  let somethingSelected = function (
  ) {

    if(!availList) return false;

    for (var i = 0; i < availList.length; i++) {
      if(availList[i].selected)
          return true;
    }
    return false;


 
  };



  const setAvailability = (e: any) => {
    {
      
      let availability_date = e.target.id.replace('avail_', '');
      let availability_type = e.target.value;

      let svc = new availServices();

      let avail = new setAvailForDate();
      avail.availability_date = availability_date;
      avail.availability_type = availability_type;

      const avails = [avail];

      document.getElementById('thetop')?.scrollIntoView({ behavior: 'smooth' });
    

      svc.setavailability(avails).then(() => {
        setupdateOk(true);
        setLoading(true);

        let svc = new availServices();

        svc.getAvailability().then((data) => {
          setavailList(data);

          setLoading(false);
        });
      });
    }
  };

  if (!availList && !loading) {
    setLoading(true);

    let svc = new availServices();

    svc
      .getAvailability()
      .then((data) => {
        setavailList(data);

        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        if (
          e.type == 'RefreshTokenException' ||
          e.responseStatus.errorCode == '403' ||
          e.responseStatus.errorCode == '401'
        ) {
          navigate('/login');
        }
      });
  }

  return (
    <div id="thetop" className="mx-auto w-full flex max-w-4xl flex-col gap-8 dark:text-white">
      <span className="text-2xl font-bold">Update your Availability</span>
      {updateOk && (
        <Toast>
          <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-sm bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
            <HiCheck className="h-5 w-5" />
          </div>
          <div className="ml-3 text-sm font-normal">Updates completed.</div>
          <Toast.Toggle />
        </Toast>
      )}
      {loading && <Spinner color="success" />}
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="py-3 px-6">
          
              Date
              <p>  <Button color="info" size="small" onClick={() => selectAll(true)}>
                   Select All
                  </Button>

                  {somethingSelected() && <Button color="info" size="small" onClick={() => selectAll(false)}>
                   Deselect All
                  </Button>}
                  </p>
            </th>
            <th scope="col" className="py-3 px-6">
              Availability
              <p>

              </p>
              <Select
                    
                      
                      value={availabilityBulk}
                      onChange={(e) => {
                        setavailabilityBulkFromSelect(e.target.value);
                      }}
                     
                    >
                      <option value="Not Specified">Choose</option>
                      <option value="All Day">All Day</option>
                      <option value="Evenings">Evenings</option>
                      <option value="Afternoons">Afternoons</option>
                      <option value="Mornings">Mornings</option>
                      <option value="Not Available">Not Available</option>
                      <option value="Booked">Already Booked</option>
                    </Select>
                  
                    {somethingSelected() && availabilityBulk &&  <Button color="info" size="small" onClick={() => applyBulkUpdates()}>
                    - Apply - 
                  </Button>}

            </th>
          </tr>
        </thead>
        <tbody>
          {availList &&
            availList.length > 0 &&
            availList.map(
              (
                {
                  selected,
                  availability_date,
                  availability_date_display,
                  availability,
                  availability_date_dow,
                },
                index
              ) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                >
                  <td className="py-4 px-6">
                  <input
                      type="checkbox"
                      checked={selected}
                      onChange={(e) => {
                        updateSelected(index, e.target.checked);
                      }}
                    /> {availability_date_dow} {availability_date_display}
                  </td>
                  <td className="py-4 px-6">
                    <Select
                      id={'avail_' + availability_date}
                      required
                      value={availability}
                      onChange={setAvailability}
                    >
                      <option value="Not Specified">Choose</option>
                      <option value="All Day">All Day</option>
                      <option value="Evenings">Evenings</option>
                      <option value="Afternoons">Afternoons</option>
                      <option value="Mornings">Mornings</option>
                      <option value="Not Available">Not Available</option>
                      <option value="Booked">Already Booked</option>
                    </Select>
                  </td>
                </tr>
              )
            )}
        </tbody>
      </table>
    </div>
  );
};

export default MemberAvailabilityPage;
