import apiServices from './apiServices';
import { officePaymentCreate,officePmtSearchReq, getLocalUnionName, Ledger_Entries,getPayment, getPayments,getLedgerEntries,stripeCheckoutStartReq,checkout_success, officePayment } from './dtos';



class pmtServices {


  baseSvc = apiServices;
  client = this.baseSvc.client;


  constructor() {

  }

  getLocalName()
  {
    
    const request = new getLocalUnionName();
    
    return this.client.get(request);

  }

  getPayment(session_id:string)
  {
    const request = new getPayment();
    request.session_id = session_id;
 
    return this.client.get(request);
  }

  sendOfficePmt(entries: officePayment[])
  {
    const request = new officePaymentCreate();
    request.payments = entries;
    
    return this.client.post(request);
  }

  searchMembers(term:string)
  {
    const request = new officePmtSearchReq();
    request.term = term
 
    return this.client.get(request);
  }





  getPayments()
  {
    const request = new getPayments();
 
    return this.client.get(request);
  }
  startPayment(items : Array<Ledger_Entries>)
  {
    const request = new stripeCheckoutStartReq();
    request.items_to_pay = items;
    return this.client.post(request);
  }
  
  finishPayment(session_id: string)
  {
    const request = new checkout_success();
    request.session_id = session_id;
    
    return this.client.post(request);
  }

  getLedgerEntries() {


    const request = new getLedgerEntries();
 

    return this.client.get(request);


  }



}




export default pmtServices;
