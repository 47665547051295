import React from "react";

export const MemberDetailHeaderRows = () => {
  return (
    <>
      <tr>
        <td className="tg-bobw" rowSpan={2}>
          ACTIONS
        </td>
        <td className="tg-bobw" rowSpan={2}>
          EMPLOYEE NAME
        </td>
        <td className="tg-bobw" rowSpan={2}>
          DEPT
        </td>
        <td className="tg-bobw" rowSpan={2}>
          RATE
        </td>
        <td className="tg-1wig"></td>
        <td className="tg-1wig" colSpan={2}>
          FIRST BREAK
        </td>
        <td className="tg-1wig" colSpan={2}>
          SECOND BREAK
        </td>
        <td className="tg-1wig"></td>
      
        <td className="tg-bobw" rowSpan={2}>
          ST TIME
        </td>
        <td className="tg-bobw" rowSpan={2}>
          1.5X TIME
        </td>
        <td className="tg-bobw" rowSpan={2}>
          2x TIME
        </td>
        <td className="tg-bobw" rowSpan={2}>
          DAILY TOTAL
        </td>
        <td className="tg-bobw" rowSpan={2}>
          QTY OTHER 1
        </td>
        <td className="tg-bobw" rowSpan={2}>
          QTY OTHER 2
        </td>
        <td className="tg-bobw" rowSpan={2}>
          COMMENTS
        </td>
      </tr>
      <tr>
        <td className="tg-1wig">IN</td>

        <td className="tg-1wig">OUT</td>
        <td className="tg-1wig">IN</td>

        <td className="tg-1wig">OUT</td>
        <td className="tg-1wig">IN</td>

        <td className="tg-1wig">OUT</td>
      </tr>
    </>
  );
};
