import { createContext, useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import apiServices from '../../services/apiServices';

type Context = {
  isLoggedIn: boolean;
  signOut(): Promise<void>;
  signIn(userName: string, password: string): Promise<void>;
};
const authContext = createContext<Context>(undefined!);
const service = apiServices;

function AuthProvider({ children }: React.PropsWithChildren<{}>) {
  const [isLoggedIn, setIsLoggedIn] = useState(() => apiServices.isloggedin());
  const navigate = useNavigate();

  const signOut = async () => {
    setIsLoggedIn(false);
    localStorage.clear();
    await service.logout();
  };
  const signIn = async (userName: string, password: string) => {
    await service.login(userName, password);
    navigate('/avail');
    setIsLoggedIn(true);
  };

  useEffect(() => {
    // handle refresh token expiration.
    service.client.onAuthenticationRequired = async () => {
      setIsLoggedIn(false);
      localStorage.clear();
      service.client.onAuthenticationRequired?.();
    };
  }, []);

  return (
    <authContext.Provider
      value={{
        isLoggedIn,
        signOut,
        signIn,
      }}
    >
      {children}
    </authContext.Provider>
  );
}

function useAuth() {
  const auth = useContext(authContext);

  if (!auth) {
    throw new Error(
      'AuthProvider must be configured to be able to use useAuth'
    );
  }

  return auth;
}

function RequireAuthenticated({
  children,
  roles,
  redirect,
}: React.PropsWithChildren<{ roles?: string[]; redirect: boolean }>) {
  const auth = useAuth();

  if (!auth.isLoggedIn) {
    if (redirect) {
      return <Navigate replace to="/login" />;
    } else return null;
  }

  if (roles) {
    if (roles.some((role) => service.hasHole(role))) return <>{children}</>;
    else if (redirect) {
      return <Navigate replace to="/login" />;
    }
    return null;
  }
  return <>{children}</>;
}

function RequireUnAuthenticated({
  children,
  redirect,
}: React.PropsWithChildren<{ redirect: boolean }>) {
  const auth = useAuth();

  if (!auth.isLoggedIn) return <>{children}</>;

  if (redirect) {
    return <Navigate replace to="/avail" />;
  } else return null;
}

export { AuthProvider, useAuth, RequireAuthenticated, RequireUnAuthenticated };
