import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Button, Spinner } from '../../lib';
import apiServices from '../../services/apiServices';
import {
  Ledger_Entries,
  local_member_ui,
  PortalPaymentDisplay,
} from '../../services/dtos';
import memberInfoServices from '../../services/memberInfoSvcs';
import pmtServices from '../../services/pmtServices';

export class PaymentsSelected {
  // @StringLength(200)
  public index: string;
  public selected: boolean;
}

const DuesPmtPage: FC = () => {
  const [processingPmt, setprocessingPmt] = useState<boolean>(false);

  const [member, setMember] = useState<local_member_ui>(new local_member_ui());

  const [ledgerEntries, setLedgerEntries] = useState<Array<Ledger_Entries>>();
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState<Array<PortalPaymentDisplay>>();

  const [selectedToPay, setselectedToPay] = useState<Array<PaymentsSelected>>(
    new Array<PaymentsSelected>()
  );
  const [errorMessage, seterrorMessage] = useState<String>(null);

  let svc2 = apiServices;
  const [baseUrl, setbaseUrl] = useState<string>(svc2.apiUrl());

  let navigate = useNavigate();

  let startPayment = function () {
    let svc = new pmtServices();

    if (!ledgerEntries) return;

    var items_to_send = new Array<Ledger_Entries>();

    for (var i = 0; i < ledgerEntries.length; i++) {
      if (ledgerEntries[i].selectedForPayment) {
        items_to_send.push(ledgerEntries[i]);
      }
    }

    if (items_to_send.length > 0) {
      setprocessingPmt(true);
      svc
        .startPayment(items_to_send)
        .then((data) => {
          console.log('sending us off to ' + data.url);
          window.location.replace(data.url);
          setprocessingPmt(false);
        })
        .catch((status) => {
          console.log(status);

          setprocessingPmt(false);

          if (status.responseStatus && status.responseStatus.message) {
            seterrorMessage(status.responseStatus.message);
          } else {
            seterrorMessage(JSON.stringify(status));
          }
        });
    }
  };

  let someSelected = function () {
    if (!ledgerEntries) return false;

    for (var i = 0; i < ledgerEntries.length; i++) {
      if (ledgerEntries[i].selectedForPayment) return true;
    }

    return false;
  };

  let failed = false;

  let updateSelected = function (
    ix: any,
    selected: boolean,
    amt_to_pay: number
  ) {
    if (!ledgerEntries) return;

    var newState = new Array<Ledger_Entries>();

    for (var i = 0; i < ledgerEntries.length; i++) {
      if (ix == i) {
        ledgerEntries[i].selectedForPayment = selected;
        ledgerEntries[i].amt_to_pay = amt_to_pay
          ? amt_to_pay
          : ledgerEntries[i].balance;
        ledgerEntries[i].amt_to_pay =
          ledgerEntries[i].amt_to_pay > ledgerEntries[i].balance
            ? ledgerEntries[i].balance
            : ledgerEntries[i].amt_to_pay;
      }

      newState.push(ledgerEntries[i]);
    }

    setLedgerEntries(newState);
  };

  useEffect(() => {
    if (!ledgerEntries && !loading && !failed) {
      setLoading(true);

      let msvc = new memberInfoServices();
      msvc.getMemberInfo().then((data) => {
        setMember(data);

        console.log(data);
      });

      let svc = new pmtServices();

      svc.getPayments().then((data) => {
        setPayments(data);
      });

      svc
        .getLedgerEntries()
        .then((data) => {
          setLedgerEntries(data);

          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          if (
            e.type == 'RefreshTokenException' ||
            e.responseStatus.errorCode == '403' ||
            e.responseStatus.errorCode == '401'
          ) {
            navigate('/login');
          }
          if (
            e.responseStatus.errorCode == '500' ||
            e.responseStatus.errorCode == '404'
          ) {
            failed = false;
          }
        });
    }
  });

  return (
    <div className="mx-auto w-full flex max-w-4xl flex-col gap-8  dark:text-gray-400">
      <span className="text-2xl font-bold">Member Payments Outstanding</span>
      {someSelected() && !processingPmt && (
        <Button onClick={startPayment}>Pay Now</Button>
      )}
      {processingPmt && (
        <Badge color="warning">Please Wait, Preparing For Payment</Badge>
      )}
      {errorMessage && <Badge color="failure">{errorMessage}</Badge>}

      {loading && <Spinner color="success" />}
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="py-3 px-6">
              Select to Pay
            </th>

            <th scope="col" className="py-3 px-6">
              Code
            </th>

            <th scope="col" className="py-3 px-6">
              Amount{' '}
            </th>
            <th scope="col" className="py-3 px-6">
              Description
            </th>
          </tr>
        </thead>
        <tbody>
          {ledgerEntries &&
            ledgerEntries.length > 0 &&
            ledgerEntries.map(
              (
                {
                  classCode,
                  balance,
                  description,
                  selectedForPayment,
                  creditAmtApplied,
                  amt_to_pay,
                },
                index
              ) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                >
                  <td className="py-4 px-6">
                    <input
                      type="checkbox"
                      checked={selectedForPayment}
                      onChange={(e) => {
                        updateSelected(index, e.target.checked, null);
                      }}
                    />
                  </td>

                  <td className="py-4 px-6">{classCode}</td>

                  <td>
                    {(!selectedForPayment || classCode == 'Credit') && (
                      <div>${balance}</div>
                    )}
                    {selectedForPayment && classCode != 'Credit' && (
                      <div>
                        ${' '}
                        <input
                          type="number"
                          value={amt_to_pay}
                          onChange={(e) => {
                            updateSelected(
                              index,
                              selectedForPayment,
                              Number(e.target.value)
                            );
                          }}
                        />
                      </div>
                    )}
                  </td>
                  {creditAmtApplied == 0 && <td>{description} </td>}
                  {creditAmtApplied != 0 && (
                    <td>
                      {description} (credit applied= ${creditAmtApplied})
                    </td>
                  )}
                </tr>
              )
            )}
        </tbody>
      </table>

      <span className="text-2xl font-bold">Member Payments History</span>

      {loading && <Spinner color="success" />}

      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="py-3 px-6">
              Date Paid
            </th>

            <th scope="col" className="py-3 px-6">
              Items Paid
            </th>

            <th scope="col" className="py-3 px-6">
              Amount Paid
            </th>

            <th scope="col" className="py-3 px-6">
              Receipt
            </th>
          </tr>
        </thead>
        <tbody>
          {payments &&
            payments.length > 0 &&
            payments.map(
              (
                {
                  items_paid_display,
                  amts_paid,
                  completed_at_display,
                  internal_session_id,
                },
                index
              ) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                >
                  <td className="py-4 px-6">{completed_at_display}</td>

                  <td>{items_paid_display}</td>
                  <td>{amts_paid}</td>
                  <td>
                    <Button
                      onClick={() =>
                        navigate('/dues-receipt/' + internal_session_id)
                      }
                    >
                      View Receipt
                    </Button>
                  </td>
                </tr>
              )
            )}
        </tbody>
      </table>
    </div>
  );
};

export default DuesPmtPage;
