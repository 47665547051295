import { useEffect, useState } from 'react';
import { HiCheck } from 'react-icons/hi';
import '../../assets/css/timesheetPage.css';
import { Label, Toast } from '../../lib';
import { DateNavigationButtons } from '../../lib/components/TimeSheet/DateNavigationButtons';
import { FinalSubmitButton } from '../../lib/components/TimeSheet/FinalSubmitButton';
import { FinalSubmitModal } from '../../lib/components/TimeSheet/FinalSubmitModal';
import { MassTextModal } from '../../lib/components/TimeSheet/MassTextModal';
import { MemberAddButton } from '../../lib/components/TimeSheet/MemberAddButton';
import { MemberDetailHeaderRows } from '../../lib/components/TimeSheet/MemberDetailHeaderRows';
import { MemberDetailRows } from '../../lib/components/TimeSheet/MemberDetailRows';
import { MemberSearchDropdown } from '../../lib/components/TimeSheet/MemberSearchDropdown';
import {
  TIMESHEET_IDS,
  TIMESHEET_ID_TO_PROPERTY_MAP,
} from '../../lib/constants';
import {
  addMember,
  getDepartments,
  getJobsForSteward,
  getStewardDetailedRecordsForJob,
  handleDeleteMember,
  saveDetailedJobRecords,
  searchMember,
  updateStewHeader,
} from '../../lib/helpers/timesheetApiUtils';
import {
  setFirstBreakInTime,
  setFirstBreakOutTime,
  setRegularInTime,
  setRegularOutTime,
  setSecondBreakInTime,
  setSecondBreakOutTime,
} from '../../lib/helpers/timesheetTimeUtils';
import {
  convertTo24Hour,
  getDateInDatePickerFormat,
  setBasicDetailForSelectedJob,
} from '../../lib/helpers/timesheetUtils';
import {
  job,
  local_member_ui,
  StewardReportDetail_ui,
} from '../../services/dtos';
const lodash = require('lodash');
const CHECK_DIRTY_RECORDS_INTERVAL = 15000;

export const TimesheetPage = () => {
  const [jobsForSteward, setJobsForSteward] = useState<job[]>([]);
  const [selectedJob, setSelectedJob] = useState<job>(null);
  const [detailedJobRecords, setDetailedJobRecords] = useState<
    StewardReportDetail_ui[]
  >([]);
  const [submittedDate, setSubmittedDate] = useState(null);
  const [departments, setDepartments] = useState<string[]>();
  const [rate1, setRate1] = useState<number>(0);
  const [rate2, setRate2] = useState<number>(0);

  const [areRecordsValid, setAreRecordsValid] = useState<boolean>(false);
  const [alreadySubmitted, setAlreadySubmitted] = useState<boolean>(false);
  const [recordValidityList, setRecordValidityList] = useState<{
    [recordKey: string]: boolean;
  }>({});
  const [showFinalSubmitModal, setShowFinalSubmitModal] =
    useState<boolean>(false);
  const [memberToSearchFor, setMemberToSearchFor] = useState<string>('');
  const [memberSearchResults, setMemberSearchResults] = useState([]);
  const [memberToAdd, setMemberToAdd] = useState<local_member_ui>();
  const [sentSmsSuccessfuly, setSentSmsSuccessfully] = useState(false);
  const [errorWhileSendingSms, setErrorWhileSendingSms] = useState(false);
  const [isFetchingDetailedJobRecords, setIsFetchingDetailedJobRecords] =
    useState(false);
  const [isSearchingForMember, setIsSearchingForMember] = useState(false);
  const [showCancelConfirmModal, setShowCancelConfirmModal] =
    useState<boolean>(false);
  const [showMassTextModal, setShowMassTextModal] = useState<boolean>(false);

  useEffect(() => {
    getJobsForSteward()
      .then((res: job[]) => {
        setJobsForSteward(res);
        //setSelectedJob(null);

        if (res.length > 0) {
          setSelectedJob(res[0]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const reset = () => {
    //setSubmittedDate("");
    setDetailedJobRecords([]);
    setDepartments([]);
    setSelectedJob(null);
    setSubmittedDate(null);

    setShowFinalSubmitModal(false);
    setAreRecordsValid(false);
    setRecordValidityList({});
  };

  const populateTableData = () => {
    if (Object.keys(selectedJob).length > 0 && submittedDate) {
      saveDetailedJobRecords(null, null, detailedJobRecords);
      getStewardDetailedRecordsForJob(
        selectedJob.job_ID,
        getDateInDatePickerFormat(submittedDate)
      )
        .then((res: StewardReportDetail_ui[]) => {
          console.log('detailed records for job');
          console.log(res);
          for (const r of res) {
            if (r.submitted_from_portal_date) {
              setAlreadySubmitted(true);
              break;
            } else {
              setAlreadySubmitted(false);
            }
          }

          getDepartments()
            .then((deptResponse) => {
              setIsFetchingDetailedJobRecords(false);
              setDepartments(deptResponse);
              setDetailedJobRecords(
                res.map((detailedJob: StewardReportDetail_ui) => {
                  return {
                    ...detailedJob,
                    department: detailedJob.department || deptResponse[0],
                    hrly_rate: detailedJob.hrly_rate || 0,
                    in_time_1: detailedJob.in_time_1,
                    out_time_1: detailedJob.out_time_1,
                    qty_hrs_1x: detailedJob.qty_hrs_1x || 0,
                    qty_hrs_1_5x: detailedJob.qty_hrs_1_5x || 0,
                    qty_hrs_2_0x: detailedJob.qty_hrs_2_0x || 0,
                    qty_hrs_ttl: detailedJob.qty_hrs_ttl || 0,
                    dirty: false,
                  };
                })
              );
            })
            .catch((err) => {
              console.error(err);
              setIsFetchingDetailedJobRecords(false);
            });
        })
        .catch((err) => {
          console.error(err);
          setIsFetchingDetailedJobRecords(false);
        });
    } else {
      setIsFetchingDetailedJobRecords(false);
      reset();
    }
  };

  const getJobsSelector = () => {
    return (
      <div className={'mb-3 xl:w-350'}>
        <select
          class="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          data-te-select-init
          onChange={(e) => {
            setSelectedJob(
              jobsForSteward.find((job) => job.job_ID === e.target.value)
            );
          }}
        >
          {jobsForSteward.map((job) => {
            return (
              <option value={job.job_ID} key={job.job_ID}>
                {job.job_ID} {job.show}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const setAllRecordsValidityList = () => {
    console.log('setting all records validity list');
    let existingRecordValidityList = {};
    for (const job of detailedJobRecords) {
      if (!job.department) {
        existingRecordValidityList[job.id] = false;
        setRecordValidityList(existingRecordValidityList);
        break;
      } /* else if (!job.hrly_rate || job.hrly_rate == 0) {
        existingRecordValidityList[job.id] = false;
        setRecordValidityList(existingRecordValidityList);
        break;
       

      } /*else if (!job.in_time_1) {
        existingRecordValidityList[job.id] = false;
        setRecordValidityList(existingRecordValidityList);
        break;
      } else if (!job.out_time_1) {
        existingRecordValidityList[job.id] = false;
        setRecordValidityList(existingRecordValidityList);
        break;
      } else if (!job.qty_hrs_1x) {
        existingRecordValidityList[job.id] = false;
        setRecordValidityList(existingRecordValidityList);
        break;
        
      } */ else if (
        Math.round(
          (job.qty_hrs_1x + job.qty_hrs_1_5x + job.qty_hrs_2_0x) * 10
        ) /
          10 !==
        Math.round(job.qty_hrs_ttl * 10) / 10
      ) {
        existingRecordValidityList[job.id] = false;
        setRecordValidityList(existingRecordValidityList);
        break;
      } else {
        existingRecordValidityList[job.id] = true;
        setRecordValidityList(existingRecordValidityList);
      }
    }
  };

  useEffect(() => {
    if (
      Object.keys(recordValidityList).length > 0 &&
      Object.keys(recordValidityList).length === detailedJobRecords.length
    ) {
      for (const recordKey of Object.keys(recordValidityList)) {
        if (!recordValidityList[recordKey]) {
          setAreRecordsValid(false);
          break;
        }
        setAreRecordsValid(true);
      }
    }
  }, [recordValidityList]);

  const updateHeader = (field: string, val: number) => {
    if (field == 'rate1') {
      setRate1(val);
      updateStewHeader(detailedJobRecords[0].stewardReportHeaderId, val, rate2);
    }

    if (field == 'rate2') {
      setRate2(val);
      updateStewHeader(detailedJobRecords[0].stewardReportHeaderId, rate1, val);
    }
  };
  const checkDirtyRecords = () => {
    let existingDetailedJobRecords = lodash.cloneDeep(detailedJobRecords);
    for (const rec of existingDetailedJobRecords) {
      if (rec.dirty) {
        saveDetailedJobRecords([rec]);
        rec.dirty = false;
      } else {
      }
    }
    // setDetailedJobRecords(existingDetailedJobRecords)
  };

  useEffect(() => {
    if (detailedJobRecords && detailedJobRecords.length > 0) {
      checkDirtyRecords();
      // saveDetailedJobRecords() //actual API call to save
      setAllRecordsValidityList();
    }
  }, [detailedJobRecords]);

  const formatHourValue = (value: string) => {
    return parseFloat(parseFloat(value).toFixed(2));
  };

  const handleChange = (
    selectedJob: StewardReportDetail_ui,
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (e.target.id) {
      const storedJob = detailedJobRecords.find(
        (detailedJob: StewardReportDetail_ui) => {
          return detailedJob.id === selectedJob.id;
        }
      );

      const targetId = e.target.id;

      switch (targetId) {
        case TIMESHEET_IDS.IN_TIME_1:
          setRegularInTime(
            storedJob,
            selectedJob,
            convertTo24Hour(e.target.valueAsDate.toISOString()),
            e.target.id,
            detailedJobRecords,
            setDetailedJobRecords
          );

          break;
        case TIMESHEET_IDS.OUT_TIME_1:
          setRegularOutTime(
            storedJob,
            selectedJob,
            convertTo24Hour(e.target.valueAsDate.toISOString()),
            e.target.id,
            detailedJobRecords,
            setDetailedJobRecords
          );

          break;
        case TIMESHEET_IDS.IN_TIME_2:
          setFirstBreakInTime(
            storedJob,
            selectedJob,
            convertTo24Hour(e.target.valueAsDate.toISOString()),
            e.target.id,
            detailedJobRecords,
            setDetailedJobRecords
          );

          break;
        case TIMESHEET_IDS.OUT_TIME_2:
          setFirstBreakOutTime(
            storedJob,
            selectedJob,
            convertTo24Hour(e.target.valueAsDate.toISOString()),
            e.target.id,
            detailedJobRecords,
            setDetailedJobRecords
          );

          break;
        case TIMESHEET_IDS.IN_TIME_3:
          setSecondBreakInTime(
            storedJob,
            selectedJob,
            convertTo24Hour(e.target.valueAsDate.toISOString()),
            e.target.id,
            detailedJobRecords,
            setDetailedJobRecords
          );

          break;
        case TIMESHEET_IDS.OUT_TIME_3:
          setSecondBreakOutTime(
            storedJob,
            selectedJob,
            convertTo24Hour(e.target.valueAsDate.toISOString()),
            e.target.id,
            detailedJobRecords,
            setDetailedJobRecords
          );

          break;
        case TIMESHEET_IDS.ONE_X_TIME:
          setDetailedJobRecords(
            detailedJobRecords.map((detailedJob: StewardReportDetail_ui) => {
              return detailedJob.id === selectedJob.id
                ? {
                    ...selectedJob,
                    qty_hrs_1x: formatHourValue(e.target.value),
                    dirty: true,
                  }
                : detailedJob;
            })
          );
          break;
        case TIMESHEET_IDS.ONE_HALF_X_TIME:
          setDetailedJobRecords(
            detailedJobRecords.map((detailedJob: StewardReportDetail_ui) => {
              return detailedJob.id === selectedJob.id
                ? {
                    ...selectedJob,
                    qty_hrs_1_5x: formatHourValue(e.target.value),
                    dirty: true,
                  }
                : detailedJob;
            })
          );
          break;
        case TIMESHEET_IDS.TWO_X_TIME:
          setDetailedJobRecords(
            detailedJobRecords.map((detailedJob: StewardReportDetail_ui) => {
              return detailedJob.id === selectedJob.id
                ? {
                    ...selectedJob,
                    qty_hrs_2_0x: formatHourValue(e.target.value),
                    dirty: true,
                  }
                : detailedJob;
            })
          );
          break;
        case TIMESHEET_IDS.COMMENT:
          setDetailedJobRecords(
            setBasicDetailForSelectedJob(
              detailedJobRecords,
              selectedJob,
              e.target.value,
              TIMESHEET_ID_TO_PROPERTY_MAP[TIMESHEET_IDS.COMMENT]
            )
          );
          break;
        case TIMESHEET_IDS.OTHER_QUANTITY_1:
          setDetailedJobRecords(
            setBasicDetailForSelectedJob(
              detailedJobRecords,
              selectedJob,
              e.target.value,
              TIMESHEET_ID_TO_PROPERTY_MAP[TIMESHEET_IDS.OTHER_QUANTITY_1]
            )
          );
          break;
        case TIMESHEET_IDS.OTHER_QUANTITY_2:
          setDetailedJobRecords(
            setBasicDetailForSelectedJob(
              detailedJobRecords,
              selectedJob,
              e.target.value,
              TIMESHEET_ID_TO_PROPERTY_MAP[TIMESHEET_IDS.OTHER_QUANTITY_2]
            )
          );
          break;
        case TIMESHEET_IDS.DEPARTMENT:
          setDetailedJobRecords(
            setBasicDetailForSelectedJob(
              detailedJobRecords,
              selectedJob,
              e.target.value,
              TIMESHEET_ID_TO_PROPERTY_MAP[TIMESHEET_IDS.DEPARTMENT]
            )
          );
          break;
        default:
      }
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubmittedDate(new Date(e.target.value));
    setRate1(0);
    setRate2(0);
  };

  const submitInvalidClassList =
    'bg-blue-500 text-white font-bold py-2 px-4 rounded opacity-50 mt-3 cursor-not-allowed';
  const submitValidClassList =
    'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 mt-3 px-4 rounded';

  const handleFinalSubmit = () => {
    if (areRecordsValid) {
      saveDetailedJobRecords(null, true, detailedJobRecords);
      reset();
    }
  };

  useEffect(() => {
    setIsSearchingForMember(true);
    if (memberToSearchFor && memberToSearchFor.length >= 3) {
      //call search member
      searchMember(memberToSearchFor)
        .then((res: StewardReportDetail_ui[]) => {
          setIsSearchingForMember(false);
          setMemberSearchResults(res);
        })
        .catch((err) => {
          console.error(err);
          setIsSearchingForMember(false);
        });
    } else {
      setIsSearchingForMember(false);
      setMemberSearchResults([]);
    }
  }, [memberToSearchFor]);

  useEffect(() => {
    if (memberToAdd != undefined) {
      if (
        memberToAdd.first_name != undefined &&
        memberToAdd.last_name != undefined
      ) {
        setMemberToSearchFor(
          memberToAdd.first_name + ' ' + memberToAdd.last_name
        );
      } else {
        if (memberToAdd.contact_email != undefined) {
          setMemberToSearchFor(memberToAdd.contact_email);
        } else {
          setMemberToAdd(null);
        }
      }
    }
  }, [memberToAdd]);

  const resetAddMember = () => {
    setMemberToAdd(null);
    setMemberToSearchFor('');
  };

  const getStewardDetailedRecordsForJobAndDepts = () => {
    getStewardDetailedRecordsForJob(
      selectedJob.job_ID,
      getDateInDatePickerFormat(submittedDate)
    ).then((res: StewardReportDetail_ui[]) => {
      for (const r of res) {
        if (r.submitted_from_portal_date) {
          setAlreadySubmitted(true);
          break;
        } else {
          setAlreadySubmitted(false);
        }
      }

      getDepartments().then((deptResponse) => {
        setDepartments(deptResponse);
        setDetailedJobRecords(
          res.map((detailedJob: StewardReportDetail_ui) => {
            return {
              ...detailedJob,
              department: detailedJob.department || deptResponse[0],
              hrly_rate: detailedJob.hrly_rate || 0,
              in_time_1: detailedJob.in_time_1,
              out_time_1: detailedJob.out_time_1,
              qty_hrs_1x: detailedJob.qty_hrs_1x || 0,
              qty_hrs_1_5x: detailedJob.qty_hrs_1_5x || 0,
              qty_hrs_2_0x: detailedJob.qty_hrs_2_0x || 0,
              qty_hrs_ttl: detailedJob.qty_hrs_ttl,
            };
          })
        );
      });
    });
  };

  const handleMemberAdd = async () => {
    if (memberToAdd !== undefined) {
      let result = await addMember(
        selectedJob.job_ID,
        detailedJobRecords[0].stewardReportHeaderId,
        memberToAdd.unique_member_id
      );
      saveDetailedJobRecords(detailedJobRecords.concat(result));
      resetAddMember();
      getStewardDetailedRecordsForJobAndDepts();
    }
  };

  const handleDateNextClick = () => {
    const currentDate = new Date(submittedDate);
    const today = new Date();

    if (currentDate.toDateString() === today.toDateString()) {
      return; // Do not increment if it's already today
    }

    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + 1);

    if (nextDate > today) {
      return; // Do not increment beyond today's date
    }

    currentDate.setDate(currentDate.getDate() + 1);
    setSubmittedDate(currentDate);
  };

  const handleDatePreviousClick = () => {
    const currentDate = new Date(submittedDate);
    currentDate.setDate(currentDate.getDate() - 1);
    setSubmittedDate(currentDate);
  };

  const setTimeToNow = (
    selectedJob: StewardReportDetail_ui,
    targetId: keyof typeof TIMESHEET_IDS
  ) => {
    const storedJob = detailedJobRecords.find(
      (detailedJob: StewardReportDetail_ui) => {
        return detailedJob.id === selectedJob.id;
      }
    );

    //    const d= (new Date()).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});

    var hrs = new Date().getHours();
    var hrsStr = hrs.toString();

    if (hrs < 10) {
      hrsStr = '0' + hrsStr;
    }

    var mins = new Date().getMinutes();
    var minsStr = mins.toString();
    if (mins < 10) {
      minsStr = '0' + minsStr;
    }

    const currentTime = hrsStr + ':' + minsStr;

    switch (targetId) {
      case TIMESHEET_IDS.IN_TIME_1:
        setRegularInTime(
          storedJob,
          selectedJob,
          currentTime,
          targetId,
          detailedJobRecords,
          setDetailedJobRecords
        );

        break;
      case TIMESHEET_IDS.OUT_TIME_1:
        setRegularOutTime(
          storedJob,
          selectedJob,
          currentTime,
          targetId,
          detailedJobRecords,
          setDetailedJobRecords
        );

        break;
      case TIMESHEET_IDS.IN_TIME_2:
        setFirstBreakInTime(
          storedJob,
          selectedJob,
          currentTime,
          targetId,
          detailedJobRecords,
          setDetailedJobRecords
        );

        break;
      case TIMESHEET_IDS.OUT_TIME_2:
        setFirstBreakOutTime(
          storedJob,
          selectedJob,
          currentTime,
          targetId,
          detailedJobRecords,
          setDetailedJobRecords
        );

        break;
      case TIMESHEET_IDS.IN_TIME_3:
        setSecondBreakInTime(
          storedJob,
          selectedJob,
          currentTime,
          targetId,
          detailedJobRecords,
          setDetailedJobRecords
        );

        break;
      case TIMESHEET_IDS.OUT_TIME_3:
        setSecondBreakOutTime(
          storedJob,
          selectedJob,
          currentTime,
          targetId,
          detailedJobRecords,
          setDetailedJobRecords
        );
        break;
    }
  };

  const handleDateConfirm = () => {
    setIsFetchingDetailedJobRecords(true);
    if (selectedJob && submittedDate) {
      populateTableData();
    } else {
      setIsFetchingDetailedJobRecords(false);
    }
  };

  const handleStartMassText = () => {
    setShowMassTextModal(true);
  };
  return (
    <div className="mx-auto w-full flex flex-col gap-8 dark:text-white">
      {sentSmsSuccessfuly && (
        <Toast classes={['fixed', 'top-5', 'right-5']}>
          <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-sm bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
            <HiCheck className="h-5 w-5" />
          </div>
          <div className="ml-3 text-sm font-normal">
            Mass SMS sent successfully
          </div>
          <Toast.Toggle />
        </Toast>
      )}
      {showFinalSubmitModal && (
        <FinalSubmitModal
          showFinalSubmitModal={showFinalSubmitModal}
          setShowFinalSubmitModal={setShowFinalSubmitModal}
          handleFinalSubmit={handleFinalSubmit}
        />
      )}
      {showMassTextModal && (
        <MassTextModal
          detailedJobRecords={detailedJobRecords}
          showMassTextModal={showMassTextModal}
          setShowMassTextModal={setShowMassTextModal}
          selectedJob={selectedJob}
          setSentSmsSuccessfully={setSentSmsSuccessfully}
          setErrorWhileSendingSms={setErrorWhileSendingSms}
          errorWhileSendingSms={errorWhileSendingSms}
        />
      )}

      <span className="text-2xl font-bold">Timesheet</span>

      <div className="flex">
        <div>
          <div className="flex mt-5">
            <div className="mb-2 flex items-center mr-2 w-24">
              <Label htmlFor="job_name" value="JOB NAME: " />
            </div>
            {getJobsSelector()}
          </div>
          <div className="flex mt-2">
            <div className="mb-2 flex items-center mr-2 w-24">
              <Label htmlFor="day" value="DATE: " />
            </div>
            <input
              class="dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              id="submitted-date"
              onChange={(e) => handleDateChange(e)}
              value={getDateInDatePickerFormat(submittedDate)}
              type={'date'}
              style={{ color: '#000' }}
              max={new Date().toISOString().split('T')[0]}
            />
            <DateNavigationButtons
              handleDateConfirm={handleDateConfirm}
              handleDatePreviousClick={handleDatePreviousClick}
              handleDateNextClick={handleDateNextClick}
              isFetchingDetailedJobRecords={isFetchingDetailedJobRecords}
            />
          </div>
          <div className="flex mt-2">
            <div className="mb-2 flex items-center mr-2 w-24">
              <Label value="PRODUCTION CO: " />
            </div>
            <div className="mb-2 flex items-center mr-2">
              <Label value={selectedJob && selectedJob.pR_Co_ID.toString()} />
            </div>
          </div>
          <div className="flex mt-2">
            <div className="mb-2 flex items-center mr-2 w-24">
              <Label value="LOCATION: " />
            </div>
            <div className="mb-2 flex items-center mr-2">
              <Label value={selectedJob && selectedJob.report_To_Location} />
            </div>
          </div>
          <div className="flex mt-2">
            <div className="mb-2 flex items-center mr-2 w-24">
              <Label value="MASS TEXT: " />
            </div>
            <span
              onClick={() => {
                if (detailedJobRecords.length > 0) {
                  handleStartMassText();
                }
              }}
              style={{
                textDecoration: 'underline',
                cursor: detailedJobRecords.length > 0 ? 'pointer' : 'default',
              }}
            >
              Start
            </span>
          </div>
          <FinalSubmitButton
            areRecordsValid={areRecordsValid}
            alreadySubmitted={alreadySubmitted}
            submitValidClassList={submitValidClassList}
            submitInvalidClassList={submitValidClassList}
            onClick={() => setShowFinalSubmitModal(true)}
          />
        </div>

        <div>
          <div className="flex mt-2">
            <div className="mb-2 flex items-center mr-2 w-24">
              <Label htmlFor="other-rate-1" value="Rate 1: " />
            </div>
            <input
              class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              id="other-rate-1"
              value={rate1}
              onChange={(event) => {
                updateHeader('rate1', +event.target.value);
              }}
              type={'number'}
            />
          </div>
          <div className="flex mt-2">
            <div className="mb-2 flex items-center mr-2 w-24">
              <Label htmlFor="other-rate-2" value="Rate 2: " />
            </div>

            <input
              class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              id="other-rate-2"
              value={rate2}
              onChange={(event) => {
                updateHeader('rate2', +event.target.value);
              }}
              type={'number'}
            />
          </div>
        </div>
      </div>

      <div className="w-full overflow-x-scroll">
        <table className="timesheet-table">
          <tbody>
            <MemberDetailHeaderRows />
            <MemberDetailRows
              detailedJobRecords={detailedJobRecords}
              departments={departments}
              alreadySubmitted={alreadySubmitted}
              handleDeleteMember={handleDeleteMember}
              setDetailedJobRecords={setDetailedJobRecords}
              handleChange={handleChange}
              setTimeToNow={setTimeToNow}
            />
          </tbody>
        </table>
      </div>

      <div
        style={{
          paddingTop: '20px',
          display: 'flex',
          gap: '20px',
          flexDirection: 'row',
        }}
      >
        <div>
          <MemberSearchDropdown
            detailedJobRecords={detailedJobRecords}
            selectedJob={selectedJob}
            alreadySubmitted={alreadySubmitted}
            setMemberToAdd={setMemberToAdd}
            memberToSearchFor={memberToSearchFor}
            memberSearchResults={memberSearchResults}
            setMemberToSearchFor={setMemberToSearchFor}
            isSearchingForMember={isSearchingForMember}
          />
        </div>
        <div className="flex flex-col">
          <MemberAddButton
            handleMemberAdd={handleMemberAdd}
            memberToAdd={memberToAdd}
            submitValidClassList={submitValidClassList}
            submitInvalidClassList={submitInvalidClassList}
          />
        </div>
      </div>
    </div>
  );
};
