import React from "react";
import { Button } from "../Button";
import { Modal } from "../Modal";
interface FinalSubmitModalProps {
  showFinalSubmitModal: boolean;
  setShowFinalSubmitModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleFinalSubmit: () => void;
}
export const FinalSubmitModal = ({
  showFinalSubmitModal,
  setShowFinalSubmitModal,
  handleFinalSubmit,
}: FinalSubmitModalProps) => {
  return (
    <Modal
      show={showFinalSubmitModal}
      onClose={() => setShowFinalSubmitModal(false)}
    >
      <Modal.Header>Are you sure?</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            This will finalize the day's hours. Are you sure?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handleFinalSubmit()}>Submit</Button>
        <Button color="gray" onClick={() => setShowFinalSubmitModal(false)}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
