import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner } from '../../lib';
import apiServices from '../../services/apiServices';
import {
  Ledger_Entries,
  local_member_ui,
  officePayment,
  officeSearchResult,
} from '../../services/dtos';
import pmtServices from '../../services/pmtServices';

export class PaymentsSelected {
  // @StringLength(200)
  public index: string;
  public selected: boolean;
}

const AdminDuesPaymewntPage: FC = () => {
  const [processingPmt, setprocessingPmt] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<officeSearchResult[]>();
  const [selectedResults, setselectedResults] = useState<officeSearchResult[]>(
    []
  );
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [summaryLabel, setsummaryLabel] = useState<string>('');
  const [confirmingPmts, setconfirmingPmts] = useState<Boolean>(false);
  const [pmtsProcessedMsg, setpmtsProcessedMsg] = useState<string>('');

  const [member, setMember] = useState<local_member_ui>(new local_member_ui());

  const [ledgerEntries, setLedgerEntries] = useState<Array<Ledger_Entries>>();
  const [loading, setLoading] = useState(false);

  let svc2 = apiServices;
  const [baseUrl, setbaseUrl] = useState<string>(svc2.apiUrl());

  let navigate = useNavigate();

  useEffect(() => {
    let cnt = 0;
    let sum = 0.0;

    for (var i = 0; i < selectedResults.length; i++) {
      cnt++;
      sum += selectedResults[i].entry_paid.amt_paid;
    }
    setsummaryLabel(
      cnt.toString() + ' Selected, Total $' + Math.round(sum * 100) / 100
    );
  });

  let updateAmt = function (ix: any, amt_paid: number) {
    if (!searchResults) return;

    var newStateSelected = new Array<officeSearchResult>();

    for (var i = 0; i < selectedResults.length; i++) {
      if (i == ix) {
        selectedResults[i].entry_paid.amt_paid = amt_paid;
      }
      newStateSelected.push(selectedResults[i]);
    }

    setselectedResults(newStateSelected);
  };

  let addSelected = function (ix: any) {
    if (!searchResults) return;

    var newStateSelected = new Array<officeSearchResult>();
    var newStateResults = new Array<officeSearchResult>();

    var existingItem = searchResults[ix];
    var exists = false;
    for (var i = 0; i < selectedResults.length; i++) {
      newStateSelected.push(selectedResults[i]);
      if (
        existingItem.member.unique_member_id ==
          selectedResults[i].member.unique_member_id &&
        existingItem.entry_paid.entry.classCode ==
          selectedResults[i].entry_paid.entry.classCode
      ) {
        exists = true;
      }
    }

    searchResults[ix].entry_paid.amt_paid =
      searchResults[ix].entry_paid.entry.balance;

    for (var i = 0; i < searchResults.length; i++) {
      if (!exists && ix == i) {
        newStateSelected.push(searchResults[i]);
      } else {
        if (!exists || ix != i) newStateResults.push(searchResults[i]);
      }
    }

    setselectedResults(newStateSelected);
    setSearchResults(newStateResults);
  };

  let setNotes = function (index: number, value: string) {
    var newState = new Array<officeSearchResult>();

    for (var i = 0; i < selectedResults.length; i++) {
      if (i == index) selectedResults[i].notes = value;

      newState.push(selectedResults[i]);
    }

    setselectedResults(newState);
  };
  let removeSelected = function (ix: any) {
    if (!selectedResults) return;

    var newState = new Array<officeSearchResult>();

    for (var i = 0; i < selectedResults.length; i++) {
      if (ix != i) {
        newState.push(selectedResults[i]);
      }
    }

    setselectedResults(newState);
  };

  let handleConfirmPayments = function () {
    //officePaymentCreate
    let svc = new pmtServices();

    var pmts = new Array<officePayment>();

    for (var i = 0; i < selectedResults.length; i++) {
      var pmt = new officePayment();

      pmt.entry_paid = selectedResults[i].entry_paid;
      pmt.note = selectedResults[i].notes;
      pmts.push(pmt);
    }

    svc.sendOfficePmt(pmts).then((data) => {
      console.log(data);
      setselectedResults([]);
      setSearchResults([]);
      setconfirmingPmts(false);
      setSearchTerm('');
      setpmtsProcessedMsg('Payments Processed');
    });
  };

  let handleSearchMemberChange = function (e: any) {
    setpmtsProcessedMsg('');

    console.log('searching for ' + e.target.value);

    setSearchTerm(e.target.value);

    if (e.target.value.length < 3) {
      return;
    }

    let svc = new pmtServices();

    svc.searchMembers(e.target.value).then((data) => {
      console.log(data);

      setSearchResults(data);
    });
  };

  let failed = false;

  useEffect(() => {});

  return (
    <div className="mx-auto w-full flex max-w-4xl flex-col gap-8  dark:text-gray-400">
      <span className="text-2xl font-bold">{pmtsProcessedMsg}</span>

      <span className="text-2xl font-bold">Search Members Making Payments</span>
      <input
        type="text"
        placeholder="lastname, firstname"
        value={searchTerm}
        onChange={handleSearchMemberChange}
      />

      {loading && <Spinner color="success" />}
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="py-3 px-6">
              Select to Pay
            </th>

            <th scope="col" className="py-3 px-6">
              Member
            </th>

            <th scope="col" className="py-3 px-6">
              Item
            </th>

            <th scope="col" className="py-3 px-6">
              Balance
            </th>
          </tr>
        </thead>
        <tbody>
          {searchResults &&
            searchResults.length > 0 &&
            searchResults.map(
              ({ member, entry_paid, selectedForPayment }, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                >
                  <td className="py-4 px-6">
                    <Button onClick={() => addSelected(index)}> Add</Button>
                  </td>

                  <td className="py-4 px-6">
                    {member.first_name} {member.last_name}
                  </td>
                  <td className="py-4 px-6">
                    {entry_paid.entry.classCode} {entry_paid.entry.description}
                  </td>
                  <td className="py-4 px-6">
                    ${entry_paid.entry.balance.toFixed(2)}{' '}
                    {entry_paid.entry.creditAmtApplied != 0 && (
                      <div>
                        (credit applied=${entry_paid.entry.creditAmtApplied})
                      </div>
                    )}
                  </td>
                </tr>
              )
            )}
        </tbody>
      </table>
      {selectedResults && selectedResults.length > 0 && (
        <div>
          <span className="text-2xl font-bold">
            Selected Payments {summaryLabel}
          </span>
          {!confirmingPmts && (
            <Button onClick={() => setconfirmingPmts(true)} color="info" pill>
              Process Payments
            </Button>
          )}
          {confirmingPmts && (
            <Button
              onClick={() => handleConfirmPayments()}
              color="success"
              pill
            >
              Confirm?
            </Button>
          )}
          {confirmingPmts && (
            <Button
              onClick={() => setconfirmingPmts(false)}
              color="failure"
              pill
            >
              Cancel
            </Button>
          )}

          {loading && <Spinner color="success" />}

          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="py-3 px-6">
                  Remove
                </th>

                <th scope="col" className="py-3 px-6">
                  Check #/Notes
                </th>
                <th scope="col" className="py-3 px-6">
                  Member
                </th>

                <th scope="col" className="py-3 px-6">
                  Item
                </th>

                <th scope="col" className="py-3 px-6">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedResults &&
                selectedResults.length > 0 &&
                selectedResults.map(
                  (
                    { member, entry_paid, notes, selectedForPayment },
                    index
                  ) => (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                    >
                      <td className="py-4 px-6">
                        <Button onClick={() => removeSelected(index)}>
                          {' '}
                          Remove
                        </Button>
                      </td>

                      <td className="py-4 px-6">
                        <input
                          type="text"
                          value={notes}
                          onChange={(event) => {
                            setNotes(index, event.target.value);
                          }}
                        />
                      </td>
                      <td className="py-4 px-6">
                        {member.first_name} {member.last_name}
                      </td>
                      <td className="py-4 px-6">
                        {entry_paid.entry.classCode}{' '}
                        {entry_paid.entry.description}
                      </td>
                      <td className="py-4 px-6">
                        <input
                          type="number"
                          value={entry_paid.amt_paid}
                          onChange={(e) => {
                            updateAmt(index, Number(e.target.value));
                          }}
                        />
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AdminDuesPaymewntPage;
