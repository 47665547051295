import type { FC } from 'react';
import { useState } from 'react';
import { HiCheck } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { Select, Spinner, Toast } from '../../lib';
import {  portal_member_skills } from '../../services/dtos';
import memberInfoServices from '../../services/memberInfoSvcs';

const MemberSkillsPage: FC = () => {
  const [skillsList, setskillsList] =
    useState<Array<portal_member_skills>>([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  let navigate = useNavigate();

  

  if (skillsList.length==0 && !loaded && !loading) {
    setLoading(true);

    let svc = new memberInfoServices();

    svc
      .getMemberSkills()
      .then((data) => {
        setskillsList(data);
      
        setLoading(false);
        setLoaded(true);

      })
      .catch((e) => {
        console.log(e);
        setLoaded(true);
        setLoading(false);
        if (
          e.type == 'RefreshTokenException' ||
          e.responseStatus.errorCode == '403' ||
          e.responseStatus.errorCode == '401'
        ) {
          navigate('/login');
        }
      });
      
  }

  return (
    <div className="mx-auto w-full flex max-w-4xl flex-col gap-8 dark:text-white">
      <span className="text-2xl font-bold">Your Skills On File</span>
    
      {loading && <Spinner color="success" />}

      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="py-3 px-6">
              Skill
            </th>
          
          </tr>
        </thead>
        <tbody>
          {skillsList &&
            skillsList.length > 0 &&
            skillsList.map(
              (
                {
                  skill_name,
                  cert_expire_date,
                 
                },
                index
              ) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                >
                  <td className="py-4 px-6">
                    {skill_name} {cert_expire_date && " expires: " +cert_expire_date}
                  </td>
                 
                </tr>
              )
            )}
        </tbody>
      </table>
    </div>
  );
};

export default MemberSkillsPage;
