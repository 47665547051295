import { StewardReportDetail_ui } from "../../services/dtos";
import { TIMESHEET_ID_TO_PROPERTY_MAP } from "../constants";

export const isEndTimeGreaterThanStartTime = (startTime:string, endTime:string): boolean => {
    
    return true;

    const start = convertTo24Hour(startTime);
    const end = convertTo24Hour(endTime);

    console.log(`Is ${startTime} > ${endTime}? - ${start>end}`)
  return start < end;
    
}

export const convertTo24Hour = (isoTimeString: string): string => {


    let [date,time] = isoTimeString.split("T");

  let [hours, minutes] = time.split(':');

  
  return `${hours}:${minutes}`;
}

export const getTimeDiff = (startTime: string, endTime:string): string => {

    if(!startTime || !startTime.includes(":") || !endTime || !endTime.includes(":"))
    {
        return "";
    }

   
    const startSplit = startTime.split(':');
    const endSplit = endTime.split(':');

    const startHours = parseInt(startSplit[0], 10);
    const startMinutes = parseInt(startSplit[1], 10);
    const endHours = parseInt(endSplit[0], 10);
    const endMinutes = parseInt(endSplit[1], 10);

    let hourDiff = endHours - startHours;
    let minuteDiff = endMinutes - startMinutes;

    if (minuteDiff < 0) {
        minuteDiff += 60;
        hourDiff--;
    }

    const formattedHourDiff = String(hourDiff).padStart(2, '0');
    const formattedMinuteDiff = String(minuteDiff).padStart(2, '0');

   // console.log(`Time diff between ${startTime} and ${endTime} is ${formattedHourDiff}:${formattedMinuteDiff}`)

    return `${formattedHourDiff}:${formattedMinuteDiff}`;
    
}

export const sumOfArray = (iterator: number[]): number => {
    return Math.round(iterator.reduce((a,b)=>a+b)*10)/10;
}

export const getHoursFromTime = (time:string): number => {

    const hours = parseInt(time.split(":")[0])
    const minutes = parseInt(time.split(":")[1])/60
    return parseFloat((hours + minutes).toFixed(2))
}

export const setSelectedJobIfAllTimesExist = (detailedJobRecords: StewardReportDetail_ui[], selectedJob: StewardReportDetail_ui, timeToSet: string, startTimeRegular: string, endTimeRegular: string, startTimeBreak1: string, endTimeBreak1: string, startTimeBreak2: string, endTimeBreak2: string,  timeId: string): StewardReportDetail_ui[] => {

    return detailedJobRecords.map((detailedJob: StewardReportDetail_ui)=>{
        return detailedJob.id === selectedJob.id ? {
            ...selectedJob, [TIMESHEET_ID_TO_PROPERTY_MAP[timeId]]: timeToSet, 
            qty_hrs_ttl: Math.round(sumOfArray([
                ( getHoursFromTime(getTimeDiff(startTimeRegular, endTimeRegular)) - 
                (getHoursFromTime(getTimeDiff(startTimeBreak1, endTimeBreak1)) + getHoursFromTime(getTimeDiff(startTimeBreak2, endTimeBreak2)))),
 
            ])*10)/10,
            dirty: true,
            qty_hrs_1_5x: 0,
            qty_hrs_2_0x: 0
        } : detailedJob
    })
}

export const setSelectedJobIf2TimesExist = (detailedJobRecords: StewardReportDetail_ui[], selectedJob: StewardReportDetail_ui, timeToSet: string, startTimeRegular: string, endTimeRegular: string, startTimeBreak1: string, endTimeBreak1: string, timeId: string): StewardReportDetail_ui[] => {
    return detailedJobRecords.map((detailedJob: StewardReportDetail_ui)=>{
        return detailedJob.id === selectedJob.id ? {
            ...selectedJob, [TIMESHEET_ID_TO_PROPERTY_MAP[timeId]]: timeToSet,  
            qty_hrs_ttl: Math.round(sumOfArray([
                (getHoursFromTime(getTimeDiff(startTimeRegular, endTimeRegular)) - 
                (getHoursFromTime(getTimeDiff(startTimeBreak1, endTimeBreak1)))),

            ])*10)/10,
            drity: true,
            qty_hrs_1_5x: 0,
            qty_hrs_2_0x: 0
        } : detailedJob
    })
}

export const setSelectedJobIf1TimeExists = (detailedJobRecords: StewardReportDetail_ui[], selectedJob: StewardReportDetail_ui, timeToSet: string, startTime: string, endTime: string, timeId: string): StewardReportDetail_ui[] => {
    console.log('setSelectedJobIf1TimeExists', timeToSet, startTime, endTime, timeId)

    
    return detailedJobRecords.map((detailedJob: StewardReportDetail_ui)=>{
        return detailedJob.id === selectedJob.id ? {
            ...selectedJob, [TIMESHEET_ID_TO_PROPERTY_MAP[timeId]]: timeToSet,  
            qty_hrs_ttl: Math.round(sumOfArray([
                getHoursFromTime(getTimeDiff(startTime, endTime)), 

            ])*10)/10,
            dirty: true,
            qty_hrs_1_5x: 0,
            qty_hrs_2_0x: 0
        } : detailedJob
    })
}
    

export const setBasicTimeForSelectedJob = (detailedJobRecords: StewardReportDetail_ui[], selectedJob: StewardReportDetail_ui, time: string, timeId: string): StewardReportDetail_ui[] => {
    return detailedJobRecords.map((detailedJob: StewardReportDetail_ui)=>{
        return detailedJob.id === selectedJob.id ? {
            ...selectedJob, [TIMESHEET_ID_TO_PROPERTY_MAP[timeId]]: time, qty_hrs_1_5x: 0,
            qty_hrs_2_0x: 0,
            dirty: true,
        } : detailedJob
    })
}

export const setBasicDetailForSelectedJob = (detailedJobRecords: StewardReportDetail_ui[], selectedJob: StewardReportDetail_ui, value: string, propertyName: string): StewardReportDetail_ui[] => {
    return detailedJobRecords.map((detailedJob: StewardReportDetail_ui)=>{
        return detailedJob.id === selectedJob.id ? {
            ...selectedJob, [propertyName]: value,
            dirty: true,
            qty_hrs_1_5x: 0,
            qty_hrs_2_0x: 0
        } : detailedJob
    })
}

export const getDateInDatePickerFormat = (date: Date):string => {
    if(!date)
    {
        return "";
    }
    return date.toISOString().slice(0, 10);
}
